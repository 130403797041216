@import "src/_variables.scss";

.login {
  height: 100vh;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #D0D1E1 #f5f5f5;
  background-image: url('/images/bg-login.svg');
  background-color: $background;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  position: relative;

  &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
  }
  &::-webkit-scrollbar-track {
      background: #f5f5f5;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #D0D1E1;
    border-radius: 4px;
    border: 0;
  }

  &_sign-up {
    background-image: none;
    .login__left {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      width: 100%;
      display: flex;
      align-items: flex-end;
    }
    .image-start {
      width: 100%;
      display: block;
      object-fit: contain;
    }
    .login__right {
      margin-left: auto;
    }
  }

  &__wrapper {
    padding: 32px;
    display: flex;
    min-height: 100%;
    position: relative;
  }

  &__left {
    position: relative;
    width: 55%;
    display: flex;
    align-items: center;
    justify-content: center;

    .image-people {
      position: absolute;
      display: block;
      margin: auto;
      width: 100%;
      height: calc(100% - 32px);
      bottom: -32px;
    }

    .icon-landing {
      position: absolute;
      background: $dark-blue;
      box-shadow: 0px 6px 24px rgba(0, 0, 0, 0.08);
      border-radius: 16px;
      padding-top: 15px;
      padding-bottom: 5px;
      bottom: 32px;
      left: 32px;
      width: 263px;
      text-align: center;
      z-index: 5;
    }
  }

  &__right {
    position: relative;
    box-shadow: 0px 6px 32px rgba(0, 0, 0, 0.02);
    border-radius: 32px;
    background: $white;
    width: 45%;
    padding: 57px 70px;
    display: flex;
    flex-direction: column;
    z-index: 5;

    .ant-form {
      flex-grow: 1;
      flex-direction: column;
      display: flex;
    }
    .sign-up-form__bottom {
      margin-top: 0;
      flex-grow: 1;
      flex-direction: column;
      display: flex;
      justify-content: flex-end;
    }
  }

  .logo {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 50px;

    svg {
      fill: $dark-blue;
    }
  }

  &__form {
    width: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    &__bottom {
      margin-top: 24px;
    }

    .form-item {
        flex-grow: 1;
    }
  }

  &__google {
    width: 100%;
    color: $blue;
    font: 18px/24px $PB;
    padding: 16px;
    background: $white;
    box-shadow: 0px 6px 24px rgba(0, 0, 0, 0.08);
    border-radius: 16px;
    cursor: pointer;
    transition: all 0.3s;

    &:hover,
    &:focus {
      color: darken($blue, 20%);
    }

    .icon-google {
      width: 24px;
      height: 24px;
      margin-left: 16px;
      vertical-align: middle;
    }
  }

  &__or {
    display: block;
    text-align: center;
    font: 20px/28px $PSB;
    color: $grey;
    margin: 12px auto;
  }

  .forgot-password {
    display: block;
    text-align: right;
    color: $violet-light;
    font: 16px/19px $PSB;
    margin-top: -10px;

    &:hover,
    &:focus {
      color: $violet;
    }
  }

  .ant-input-affix-wrapper {
    background: $background;
    border-radius: 12px;
    font: 16px/19px $PSB;
    color: $dark-blue;
    padding: 16px 24px;
    border: none;

    &:focus,
    &.ant-input-affix-wrapper-focused {
      box-shadow: none;
    }

    input.ant-input {
      font: 16px/19px $PSB;
      color: $dark-blue;
      background: $background;

      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px $background inset !important;
      }

      &:focus,
      &:hover {
        background: $background;
      }
    }
  }

  .ant-form-item-has-error {

    & :not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
    & :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
    & :not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover,
    & :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
      background: $background;
      border: none;
      box-shadow: none;
    }
  }

  &__continue {
    width: 100%;

    .ant-btn {
      background: linear-gradient(90deg, #9571F6 0%, $violet 100%);
      border-radius: 16px;
      color: $white;
      width: 100%;
      padding: 15px;
      height: auto;
      border: none;
      transition: all .4s ease-in-out;

      &:hover,
      &:focus {
        background-size: 300% 100%;
        background-position: 0 100%;
        background-image: linear-gradient(90deg, #9571F6 0%, $violet 100%);
        border: none;
        color: $white;
      }

      &:after {
        display: none;
      }

      &:disabled {
        background: $grey-light;
      }

      .button__text {
        font: 18px/22px $PB;

        
      }

      svg {
        fill: $white;
        margin-left: 16px;
        width: 16px;
        height: auto;
      }

      &:disabled {
        cursor: default;
        background: $grey-light;
        color: $grey2;

        svg {
          fill: $grey2;
        }
      }
    }
  }

  &__sign-up {
    text-align: center;
    font: 16px/19px $PSB;
    color: $dark;
    margin-bottom: 25px;

    a {
      color: $violet-light;
      margin-left: 5px;

      &:hover,
      &:focus {
        color: $violet;
      }
    }
  }
  
  &__error-message {
    position: absolute;
    display: block;
    padding: 12px 30px;
    width: 100%;
    font: 16px/20px $PB;
    color: $white;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    background: $red;
    border-radius: 32px 32px 0 0;

    &__icon {
      vertical-align: middle;
      width: 16px;
      height: 16px;
      margin-right: 13px;
    }

    .ant-btn {
      position: absolute;
      background: transparent;
      width: 14px;
      height: 14px;
      border: none;
      padding: 0;
      right: 24px;
      top: 15px;

      &:hover,
      &:focus,
      &:active {
        background: transparent;
      }

      svg {
        fill: $white;
      }
    }
  }

  @media screen and (max-width: 992px) {
    position: relative;
    padding-top: 48px;
    &__wrapper {
      flex-direction: column;
    }
    &__left {
      display: none;
    }
    &_sign-up {
      background-image: url('/images/bg-login.svg');
      .login__left {
        display: none;
      }
      .login__right {
        margin-left: 0;
      }
    }

    &__right {
      width: 100%;
      flex-grow: 1;
      position: static;
    }
    &__error-message {
      position: absolute;
      padding: 8px 24px;
      font-size: 10px;
      border-radius: 0;
  
      &__icon {
        width: 12px;
        height: 12px;
        margin-right: 8px;
        margin-bottom: 2px;
      }
  
      .ant-btn {
        top: 8px;
      }
    }
  }

  @media screen and (max-width: 768px) {
    padding: 48px 24px 24px;
    background: #ffffff;

    &__right {
      box-shadow: none;
      border-radius: 0;
      padding: 0;
    }

    .logo {
      display: block;
      margin-bottom: 40px;
    }
  }

  @media screen and (max-width: 576px) {
    .logo {
      a {
        display: inline-block;
        width: 94px;
        img {
          max-width: 100%;
          height: auto;
        }
      }
    }

    &__google {
      font-size: 14px;
      padding: 10px;
    }

    &__or {
      font-size: 14px;
    }
    .ant-input-affix-wrapper {
      font-size: 14px;
      padding: 12px 24px;
      input.ant-input {
        font-size: 14px;
      }
    }
    .forgot-password {
      font-size: 12px;
    }
    &__sign-up {
      font-size: 12px;
      margin-bottom: 12px;
    }
    &__continue {
      .ant-btn {
        padding: 11px 15px;
        .button__text {
          font-size: 16px;
        }
      }
    }
  }
}