@import "src/_variables.scss";

.create-account-button {
  height: 56px;
  width: 220px;
  margin: auto;
  &.maxSize {
    padding-bottom: 96px;
    width: 476px;
    .ant-btn {
      height: 60px;
      .button__text {
        font: 18px/22px $PB;
      }
    }
  }
  .ant-btn {
    background: linear-gradient(90deg, #9571F6 0%, $violet 100%);
    border-radius: 16px;
    color: $white;
    width: 100%;
    padding: 15px;
    height: 56px;
    border: none;
    transition: all .4s ease-in-out;

    &:hover,
    &:focus {
      background-size: 300% 100%;
      background-position: 0 100%;
      background-image: linear-gradient(90deg, #9571F6 0%, $violet 100%);
      border: none;
      color: $white;
    }

    &:after {
      display: none;
    }

    &:disabled {
      background: $grey-light;
    }

    .button__text {
      display: flex;
      justify-content: center;
      font: 16px/18px $PSB;
      .icon-arrow {
        padding-left: 18px;
      }
    }

    svg {
      fill: $white;
      width: 16px;
      height: auto;
    }

    &:disabled {
      cursor: default;
      background: $grey-light;
      color: $grey2;

      svg {
        fill: $grey2;
      }
    }
  }
}
