@import "src/_variables.scss";

.criteria-button-wrapper {
  text-align: center;
  height: 100%;
  min-height: 56px;

  .icon-button {
    max-width: 155px;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, $background 0%, $white 100%);
    box-shadow: 0px 6px 10px rgba(161, 181, 216, 0.22), inset 0px 4px 4px rgba(147, 157, 244, 0.17);
    border-radius: 16px;
    border: none;
    padding: 0;

    &:hover,
    &:focus {
      background: linear-gradient(180deg, $background 0%, $white 100%);
    }

    &[disabled] {
      background: linear-gradient(180deg, $background 0%, $white 100%);
      box-shadow: inset 0px 4px 4px rgba(147, 157, 244, 1);
      opacity: 0.17;
      cursor: default;
      pointer-events: none;

      .icon-button-content {
        &__icon {
          box-shadow: inset 0px 4px 4px rgba(147, 157, 244, 1);
        }
      }
    }

    &.button-fill {
      background: linear-gradient(#DDF2FF 10%, #C7C6FF 100%);
    }
  }

  .icon-button-content {
    display: flex;
    align-items: center;
    padding: 0 12px 0 16px;

    .ant-btn {
      font-size: 12px;
    }

    &__icon {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      min-width: 24px;
      width: 24px;
      height: 24px;
      // border-radius: 16px;
      pointer-events: none;
      margin-right: 6px;

      &:hover {
        background: linear-gradient(180deg, #DDF2FF 0%, #DEDDFF 100%);
        box-shadow: 0px 6px 10px rgba(161, 181, 216, 0.22), inset 0px 4px 4px rgba(147, 157, 244, 0.17);
      }
    }

    &__badge-icon {
      position: absolute;
      bottom: 0;
      right: 0;
      height: 16px;
    }

    &__message {
      margin: auto;
      padding: 7px 11px 7px 6px;
      font: 12px/14px $PSB;
      color: $dark-blue;
      white-space: normal;
      text-align: left;
      pointer-events: none;
    }
  }
}