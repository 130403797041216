@import "src/_variables.scss";

.locations-select {
  height: 56px;
  width: 414px;
  margin-right: 40px;
  margin-bottom: 15px;
  &.isMap {
    min-width: 450px;
  }
}
