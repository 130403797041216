@import './src/variables';

.icon-button-wrapper {
  .icon-button {
    max-width: 132px;
    width: 100%;
    height: auto;
    background: linear-gradient(180deg, $background 0%, $white 100%);
    box-shadow: 0px 6px 10px rgba(161, 181, 216, 0.22), inset 0px 4px 4px rgba(147, 157, 244, 0.17);
    border-radius: 16px;
    border: none;
    padding: 0;
    margin-bottom: 16px;

    &:hover,
    &:focus {
      background: linear-gradient(180deg, $background 0%, $white 100%);
    }

    &[disabled] {
      background: linear-gradient(180deg, $background 0%, $white 100%);
      box-shadow: inset 0px 4px 4px rgba(147, 157, 244, 1);
      opacity: 0.17;
      cursor: default;
      pointer-events: none;

      .icon-button-content {
        &__icon {
          box-shadow: inset 0px 4px 4px rgba(147, 157, 244, 1);
        }
      }
    }
  }

  .icon-button-content {
    position: relative;
    display: flex;

    &__icon {
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 56px;
      width: 56px;
      height: 56px;
      background: linear-gradient(180deg, $background 0%, $white 100%);
      box-shadow: 0px 6px 10px rgba(161, 181, 216, 0.22), inset 0px 4px 4px rgba(147, 157, 244, 0.17);
      border-radius: 16px;
      pointer-events: none;

      &:hover {
        background: linear-gradient(180deg, #DDF2FF 0%, #DEDDFF 100%);
        box-shadow: 0px 6px 10px rgba(161, 181, 216, 0.22), inset 0px 4px 4px rgba(147, 157, 244, 0.17);
      }
    }

    .icon-fill {
      background: linear-gradient(#DDF2FF 10%, #C7C6FF 100%);
    }

    &__badge-icon {
      position: absolute;
      top: 40px;
      left: 40px;
    }

    &.icon-check {

      .icon-button-content__icon {
        background: linear-gradient(180deg, #DDF2FF 0%, #DEDDFF 100%);
        box-shadow: 0px 6px 10px rgba(161, 181, 216, 0.22), inset 0px 4px 4px rgba(147, 157, 244, 0.17);
      }

      // &:before {
      //   position: absolute;
      //   content: "";
      //   display: block;
      //   background: url("/images/icon-check.svg") no-repeat center/100%;
      //   width: 16px;
      //   height: 16px;
      //   right: 0;
      //   bottom: 0;
      // }
    }

    &__message {
      margin: auto;
      padding: 7px 11px 7px 6px;
      font: 12px/14px $PSB;
      color: $dark-blue;
      white-space: normal;
      text-align: left;
      pointer-events: none;
    }
  }
}

.white-button {
  .ant-btn {
    box-shadow: 0px 6px 24px rgba(0, 0, 0, 0.08);
    background: white !important;
    .button__text {
      color: #908DB9;
      font-family: 'Proxima Nova Bold';
    }
  }
}