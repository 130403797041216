@import './src/variables';

.masked-input-wrapper {

  .ant-input-affix-wrapper, .ant-input {
    background: $background;
    border-radius: 12px;
    font: 16px/19px $PSB;
    color: $dark-blue;
    padding: 16px 24px;
    border: none;

    &:focus,
    &.ant-input-affix-wrapper-focused {
      box-shadow: none;
    }

    input {
      font: 16px/19px $PSB;
      color: $dark-blue;
      background: $background;

      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px $background inset !important;
      }

      &:focus,
      &:hover {
        background: $background;
      }
    }
  }
}
