@import './src/variables';

.icon-button-wrapper {
  margin-right: 10px;

  .icon-button {
    height: auto;
    border-radius: 16px;
    background: $button-background;
  }

  .icon-button-content {
    display: flex;

    &__message {
      margin: auto;
    }
  }
}

.custom-slider-wrapper {
  .MuiSlider-thumb[data-index='1'] {
    background: url("/images/icon-star.svg") no-repeat center/ 16px 16px transparent !important;

    &:before {
      box-shadow: none;
    }
  }

  .MuiSlider-thumb[data-index='2'],
  .MuiSlider-thumb[data-index='0'] {
    width: 12px;
    height: 12px;
    z-index: 2;


    &::after {
      width: 12px;
      height: 12px;
      background: $white;
      border: 1px solid #6044F5;
      box-shadow: 0px 4px 16px 5px rgba(88, 62, 245, 0.13);
    }
  }

  .MuiSlider-rail {
    color: $grey-light;
    opacity: 1;
  }

  .MuiSlider-root {
    height: 2px;
    color: $violet;
    padding: 10px 0 12px;
  }

  .MuiSlider-thumb:hover,
  .MuiSlider-thumb.Mui-focusVisible {
    box-shadow: none;
  }
}
.custom-slider-wrapper.with-padding {
  padding-left: 41px;
  padding-right: 41px;
  padding-top: 20px;
}
