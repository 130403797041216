@import "src/variables";

.no-results-wrapper {
  width: 100%;
  padding-bottom: 32px;
  &.isMap {
    width: 40%;
  }
  .ant-spin-nested-loading {
      top: 0;
  }

  .no-results {
    display: block;
    background: white;
    border-radius: 24px;

    &.isMap {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 24px;
    }

    &.topMatches {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }

    &__messages {
      margin: 0 auto;
      padding-top: 50px;
      width: fit-content;
      justify-content: center;

      .empty-button {
        padding-top: 10px;
        padding-right: 15px;
      }

      &-title {
        display: flex;
        justify-content: center;
        font: 24px/28px $PB;
        color: #1B235E;
        padding-bottom: 12px;
      }

      &-description {
        white-space: break-spaces;
        text-align: center;
        font: 16px/24px $PR;
        color: #1B235E;
      }
    }

    &__image {
      display: flex;
      justify-content: center;
      padding-top: 20px;
      .top-matches-list {
        max-height: 66vh;
        width: 100%;
        border-radius: 24px;
        @media (min-width: 1550px) {
          max-height: 70vh;
        }
      }
      .top-matches-map {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 24px;
        padding-top: 30px;
        width: 100%;
        height: calc(100vh - 260px);
      }

      &.withBottomSpace {
        padding-bottom: 30px;
      }
    }
  }
}
