@import "src/variables";

.units-info-window {
  width: 240px;
  height: 213px;
  background: $white;
  box-shadow: 0 6px 32px rgba(0, 0, 0, 0.02);
  border-radius: 24px;
  .house-item {
    display: block;
    padding: 12px 12px 8px;
    &__address {
      color: $dark-blue;
      font: 12px/20px $PB;
      font-style: normal;
      font-weight: 700;
      cursor: pointer;
      padding-top: 12px;
    }
    &__address:hover {
      color: #9571F6;
    }
    &__general {
      display: flex;
      &__info {
        padding-left: 12px;
        .criteria-price-tag {
          font: 14px/14px $PSB;
          font-style: normal;
          font-weight: 600;
          height: 24px;
          border-radius: 8px;
          padding: 6px 6px 4px;
        }
        &__statuses {
          display: flex;
          padding-top: 12px;
          .status {
            font: 10px/16px $PSB;
            font-style: normal;
            font-weight: 600;
          }
        }
      }
    }
    &__criteria-tags {
      display: flex;
      justify-content: flex-start;
      gap: 10px;
      padding-top: 7px;
      .criteria-tag {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 24px;
        font: 10px/12px $PB;
        font-style: normal;
        font-weight: 700;
        border-radius: 8px;
        min-width: 48px;
      }
    }
  }

  .divider {
    margin: 0 12px;
    background: $background;
    height: 1px;
  }

  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #D0D1E1 #f5f5f5;

  &::-webkit-scrollbar {
    height: 6px;
    width: 6px;
  }
  &::-webkit-scrollbar-track {
    background: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #D0D1E1;
    border-radius: 4px;
    border: 0;
  }
}
