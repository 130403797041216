@import './src/variables';

.input-number-wrapper {
  position: relative;
}

.input-number {
  position: relative;

  &__title {
    display: flex;
    width: 100%;
    position: absolute;
    bottom: calc(100% + 9px);
    left: 0;
    font: 12px/15px $PR;
    color: $grey2;

    .input-number-icon {
      margin-left: auto;
    }
  }


  .ant-input-number {
    width: 100%;
    padding: 10px;
    background: $background;
    border-radius: 12px;
    border: none;
    .ant-input-number-handler-wrap {
      display: none;
    }
  }
  .ant-input-number:focus {
    box-shadow: none;
    border: none;
  }

  .ant-input-number-input {
    border: none;
    background: $background;
    border-radius: 12px !important;
    font: 16px/19px $PB;
    color: $dark-blue;
    padding: 17px;
  }

  .ant-input:focus,
  .ant-input-focused {
    box-shadow: none;
  }

  &.input-star {
    &:after {
      position: absolute;
      content: "";
      width: 16px;
      height: 16px;
      right: 17px;
      top: 50%;
      transform: translateY(-50%);
      background: url("/images/icon-star.svg") no-repeat center/100%;
    }
  }
}

.ant-input {
  color: #1B235E !important;
}

input::placeholder {
  color: #1B235E !important;
  opacity: 0.5;
}