// COLORS
$black: #000;
$dark: #282C4D;
$dark2:#32396E;
$white: #ffffff;
$background: #f4f7fc;
$button-background: #fafbfd;
$red: #FB5D5D;
$red-light: #FEE5E5;
$yellow: #DF992F;
$yellow-light: #F5E237;
$orange-light: #F5B22F;
$orange: #FF8484;
$salmon: #FDA18D;
$purple-light: #A562E8;
$purple: #9400D3;
$blue-light: #2FCDF5;
$blue: #5085EC;
$dark-blue: #1B235E;
$violet: #583EF5;
$violet-light: #939DF4;
$grey: #908DB9;
$grey2: #A9A9C4;
$grey3:#D0D1E1;
$grey-light:#EBECEF;
$grey-light2: #EFF1F6;
$green: #207d44;
$green2: #4AB272;
$green-light: #76C797;
$green-yellow: #629213;
$yellow-dark: #A9990A;
$orange-dark: #A86C18;
$red-dark: #932E2E;


//SIZE
$border-radius-container: 25px;
$border-radius-button: 15px;
$height-button: 45px;
$title-size: 20px;

$box-shadow: 10px 10px 20px rgba(61, 66, 105, 0.1);

//FONT FAMILY
$PR: "Proxima Nova Regular";
$PSB: "Proxima Nova Semibold";
$PB: "Proxima Nova Bold";
