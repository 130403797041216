@import "src/_variables.scss";

.profile-page {
  .profile-message-container {
    display: flex;
    align-items: center;
    height: 44px;
    background: #207d44;

    .profile-message {
      display: flex;
      padding: 12px 32px;
      font: 16px $PSB;
      color: white;
      .check-icon {
        padding-right: 12px;
      }
    }
    .profile-message-close {
      margin-left: auto;
      padding-right: 36px;
      svg {
        fill: white;
      }
    }
  }
  .profile-message-container.red {
    background: $red;
  }

  .profile-container {
    padding: 8px 33px 20px 32px;
    min-height: 100vh;
    display: flex;
    flex-direction: column;

    .profile-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding-top: 20px;
      padding-bottom: 50px;
      &__title {
        font:22px $PSB;
      }
    }
    .profile-content {
      display: flex;
      width: 100%;
      flex-grow: 1;
      &__left {
        width: 67%;
        background: white;
        border-radius: 24px;

        .header-icons {
          .hihome-icon {
            width: 100%;
          }
          .profile-icon {

          }
        }
        .profile-form {
          padding: 12px 32px;
        }
      }
      &__right {
        width: 32%;
        background: white;
        border-radius: 24px;
        margin-left: auto;
        padding: 32px 22px 27px 22px;
        display: flex;
        flex-direction: column;

        .title {
          padding-bottom: 12px;
          font:24px $PSB;
        }
        .description {
          font:16px $PR;
        }
        .image {
          display: flex;
          justify-content: center;
          flex-grow: 1;
          align-items: flex-end;
        }
      }
    }
  }
}

.form-input.invisible {
  display: none;
}

.form-input {
  .ant-input-affix-wrapper-disabled {
    background: #EBECEF !important;
  }
  .ant-input-disabled {
    background: #EBECEF !important;
  }
  .input-with-button {
    display: flex;
    align-items: baseline;

    .ant-form-item {
      width: 100%;
    }

    &__button {
      display: flex;
      margin-left: auto;

      svg {
        fill: $white;
        margin-left: 16px;
        width: 16px;
        height: auto;
      }
    }
  }
  &__message {
    font:12px $PR;
    color: #A9A9C4;
    padding-bottom: 5px;
  }
}

.profile-button.with-padding {
  top: 52px;
}

.profile-form-columns__password {
  .password-message{
    font: 12px $PR;
    color: #A9A9C4;
    padding-bottom: 5px;
  }
  .ant-input-password {
    z-index: 0 !important;;
}
}

.change-password-btn, .save_password {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .ant-btn {
    background: linear-gradient(90deg, #9571F6 0%, $violet 100%);
    border-radius: 16px;
    color: $white;
    padding: 15px;
    width: 170px;
    height: 50px;
    border: none;
    transition: all .4s ease-in-out;


    &:hover,
    &:focus {
      background-size: 300% 100%;
      background-position: 0 100%;
      background-image: linear-gradient(90deg, #9571F6 0%, $violet 100%);
      border: none;
      color: $white;
    }

    &:after {
      display: none;
    }

    &:disabled {
      background: $grey-light;
    }

    &:disabled {
      cursor: default;
      background: $grey-light;
      color: $grey2;
    }
  }
}


.profile-button {
  position: absolute;
  top: 13px;
  right: 33px;

  .ant-btn {
    background: linear-gradient(90deg, #9571F6 0%, $violet 100%);
    border-radius: 16px;
    color: $white;
    padding: 15px;
    width: 200px;
    height: 60px;
    border: none;
    transition: all .4s ease-in-out;

    &:hover,
    &:focus {
      background-size: 300% 100%;
      background-position: 0 100%;
      background-image: linear-gradient(90deg, #9571F6 0%, $violet 100%);
      border: none;
      color: $white;
    }

    &:after {
      display: none;
    }

    &:disabled {
      background: $grey-light;
    }

    .button__text {
      font: 18px/22px $PB;
    }

    &:disabled {
      cursor: default;
      background: $grey-light;
      color: $grey2;
    }
  }
}
