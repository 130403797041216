@import "src/variables";

.all-criteria-modal {
  @media (min-width: 1600px) {
    top: 15% !important;
    width: 70% !important;
  }
  @media (max-height: 1000px) and (min-width: 1000px) {
    top: 5% !important;
  }
  .ant-modal-content {
    padding: 15px;
    background: #F4F7FC;
    box-shadow: 0 6px 32px rgba(0, 0, 0, 0.02);
    border-radius: 24px;
    .ant-modal-body {
      padding: 15px;
    }
  }

  &__body {}
  &__footer {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 0 20px;
    .back-button {
      width: 50%;
      min-width: 50%;
      text-align: start;
      .ant-btn {
        border: none;
        width: 227px;
        height: 60px;
        color: $grey;
        font: 18px/24px $PB;
        padding: 16px;
        background: $white;
        box-shadow: 0 6px 24px rgba(0, 0, 0, 0.08);
        border-radius: 16px;
        cursor: pointer;
        transition: all 0.3s;

        &:after {
          display: none;
        }

        &:disabled {
          background: $grey-light;
        }

        .button__text {
          justify-content: center;
          font: 18px/22px $PB;
        }
      }
      .back-button-text {
        display: flex;
        margin-right: 15px;
      }

      .back-button-icon {
        svg {
          fill: $grey;
        }
        margin-right: 15px;
      }
    }

    .back-button:hover {
      .back-button-text {
        color: darken($grey, 20%);
      }

      svg {
        fill: darken($grey, 20%);
      }
    }

    .right-button {
      width: 100%;
      min-width: 50%;
      text-align: end;
      .ant-btn {
        background: linear-gradient(90deg, #9571F6 0%, $violet 100%);
        border-radius: 16px;
        color: $white;
        width: 227px;
        padding: 15px;
        height: 60px;
        border: none;
        transition: all .4s ease-in-out;

        @media (max-width: 576px) {
          padding: 11px 15px;
          height: 44px;
        }

        &:hover,
        &:focus {
          background-size: 300% 100%;
          background-position: 0 100%;
          background-image: linear-gradient(90deg, #9571F6 0%, $violet 100%);
          border: none;
          color: $white;
        }

        &:after {
          display: none;
        }

        &:disabled {
          background: $grey-light;
        }

        .button__text {
          justify-content: center;
          font: 18px/22px $PB;
          @media (max-width: 576px) {
            font-size: 16px;
          }
        }

        svg {
          fill: $white;
          width: 16px;
          height: auto;
        }

        &:disabled {
          cursor: default;
          background: $grey-light;
          color: $grey2;

          svg {
            fill: $grey2;
          }
        }
      }
    }
  }
  &__title {
    font: 40px/44px $PSB;
    font-style: normal;
    font-weight: 600;
    text-align: center;
    color: $dark-blue;
  }
}
