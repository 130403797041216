@import "src/variables";

.first-step {
  margin: 20px;
  display: flex;
  gap: 24px;

  .criteria-list-container {
    background: $white;
    box-shadow: 0 6px 32px rgba(0, 0, 0, 0.02);
    border-radius: 24px;
    padding: 25px;
    display: flex;
    width: 100%;

    .coming-soon {
      margin-left: auto;
      &__title {
        color: $grey2;
        font: 14px/17px $PSB;
        margin-bottom: 12px;
      }

      &__content {
        display: grid;
        grid-template-columns: 50% 50%;
        .criterion-button {
          max-width: 120px;
          width: 120px;
          .icon-button-content {
            &__icon {
              min-width: 50px;
              width: 50px;
              height: 50px;
              svg {
                width: 30px;
                height: 30px;
              }
            }
            &__message {
              font: 10px/12px $PSB;
            }
            &__badge-icon {
              top: 34px;
              left: 34px;
            }
          }
          &.active {
            width: 50px;
            max-width: 50px;
          }
        }
      }
    }

    .criteria {
      margin-right: 50px;
      .criteria-content {
        &__content {
          display: flex;
          flex-wrap: wrap;
          .criterion-button {
            max-width: 120px;
            width: 120px;
            .icon-button-content {
              &__icon {
                min-width: 50px;
                width: 50px;
                height: 50px;
                svg {
                  width: 30px;
                  height: 30px;
                }
              }
              &__message {
                font: 10px/12px $PSB;
              }
              &__badge-icon {
                top: 34px;
                left: 34px;
              }
            }
            &.active {
              width: 50px;
              max-width: 50px;
            }
          }
        }

        .criteria-title {
          font: 14px/17px $PSB;
          margin-bottom: 12px;
          color: #1B235E;
        }
      }
    }
  }

  .criterion-filter-container {
    position: relative;
    min-width: 48%;
    padding: 24px;
    background: $white;
    border-radius: 24px;

    .filters-title {
      .filters-close {
        .ant-btn {
          top: 23px;
          right: 24px;
        }
      }
    }
    .footer {
      display: flex;
      width: calc(100% - 48px);
      justify-content: space-between;
      position: absolute;
      bottom: 25px;
      padding: 0 19px;
      align-items: center;
      .unselect-criteria-button {
        display: flex;
        font: 16px/19px $PSB;
        font-style: normal;
        height: 52px;
        align-items: center;
        .ant-btn {
          color: $violet;
          border: none;
          box-shadow: none;
          width: 120px;
          padding: 0;
          .button__text {
            text-decoration-line: underline;
          }
          :hover {
            color: #9571F6;
          }
        }
        .ant-btn:disabled {
          background: transparent;
          color: $grey2;
        }
      }
      .remote-submit-button-wrapper {
        width: 160px;
        height: 52px;
        .btn-default-position {
          position: absolute;
          margin: 0;
          .ant-form-item {
            margin-bottom: 0;
          }
          .ant-btn {
            width: 160px;
          }
        }
      }
    }

    .priority-criterion-content {
      display: block;
      position: absolute;
      top: 35%;
      width: calc(100% - 48px);
      text-align: center;
      &__icon {
        width: 65px;
        margin: 24px auto;
        svg {
          width: 64px;
          height: 64px;
        }
      }
      &__text {
        width: 100%;
        font: 16px/24px $PR;
        font-style: normal;
        color: $dark-blue;
      }
    }
  }
}
