@import "src/variables.scss";

.locations-step-container {
  margin: auto;
  padding: 15px 0;
  width: 60%;
  .locations-step {
    &__title {
      display: block;
      padding: 15px 0;
      &__text {
        font: 40px/44px $PSB;
        font-style: normal;
        font-weight: 600;
        text-align: center;
        color: $dark-blue;
        @media (max-width: 1440px) {
          font: 36px/40px $PSB;
        }
      }
      .locations-step__subtitle {
        display: block;
        &__text {
          text-align-last: center;
          font: 20px/32px $PR;
          color: $grey;
          @media (max-width: 1440px) {
            font: 18px/28px $PR;
          }
        }
      }
    }
  }
  .locations-form {
    margin: auto;
    width: 100%;
    max-width: 390px;

    .location-search {
    
      &__input {
        max-width: 100%;
        width: 100%;
      }
    }
  }
}
