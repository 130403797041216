@import "src/_variables.scss";


.bottom-message {
  text-align: center;
  font-size: 11px;
  padding-top: 8px;
  color: #A9A9C4;
  a {
    padding: 0 4px;
    color: #939DF4;
  }
  @media screen and (max-width: 768px) {
    max-width: 248px;
    margin: 0 auto;
  }
}

.homepage-button {
  display: flex;
  position: absolute;
  background: #F4F7FC;
  box-shadow: none;
  border: none;
  z-index: 5;
  font: 18px $PSB;
  color: #1B235E;
  .button__text {
    display: flex;
    align-items: center;
  }
  &__icon {
    padding-right: 12px;
  }
  svg {
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    fill: #1B235E;
  }
  @media screen and (max-width: 992px) {
    position: static;
    margin-bottom: 16px;
  }
  @media screen and (max-width: 768px) {
    background: transparent;
  }
}
.homepage-button:hover {
  background: #F4F7FC;
  svg {
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    fill: #40a9ff;
  }
}

.email-confirm-message {
  text-align: center;
  font: 24px $PSB;
}
.email-confirm-description {
  text-align: center;
  font: 16px $PSB;
}
.email-confirm-image {
  text-align: center;
  margin: 50px 0px;
}