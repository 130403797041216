@import './src/variables';


.custom-table-container {
  width: 100%;

  .row-value {
    height: 32px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    font: 16px/18px $PSB;
    width: 100px;
    margin: auto;
  }

  .price-column {
    .row-value {
      width: 150px;
    }
  }

  .ant-table-ping-right:not(.ant-table-has-fix-right) .ant-table-container::after {
    box-shadow: none;
  }
}

.table_custom {

  .ant-table-pagination.ant-pagination {
    margin-top: 40px;
    padding-right: 80px;
  }

  .ant-pagination-prev,
  .ant-pagination-next,
  .ant-pagination-jump-prev,
  .ant-pagination-jump-next {
    box-shadow: 0px 6px 32px rgba(0, 0, 0, 0.02);

    .ant-pagination-item-link {
      background: $white;
      box-shadow: 0px 6px 32px rgba(0, 0, 0, 0.02);
      border-radius: 12px;
      border: none;
      font-size: 16px;
      width: 44px;
      height: 44px;
      min-width: 44px;

      .anticon svg {
        fill: $violet;
      }

      &:disabled {
        .anticon svg {
          fill: $grey3;
        }
      }
    }
  }

  .ant-pagination-item {
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    border: none;
    width: 44px;
    height: 44px;
    min-width: 44px;


    a {
      color: $dark-blue;
      font: 18px $PSB;
    }
  }

  .ant-pagination-item-active a {
    color: $violet;
  }
}