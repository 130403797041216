@import "src/_variables";

.switcher {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 290px;
  height: 60px;
  border-radius: 60px;
  background: $white;
  box-shadow: inset 0 2px 4px rgba(149, 113, 246, 0.15);

  .switch-button {
    width: 129px;
    height: 44px;
    border-radius: 24px;
    background: $white;
    border: none;
    box-shadow: none;
    font: 14px $PSB;
    color: $dark-blue;

    &:hover,
    &:focus,
    &:active {
      color: $dark-blue;
    }

    svg {
      fill: $dark-blue;
      margin-right: 12px;
      vertical-align: middle;
    }
  }

  .switch-button.active {
    background: linear-gradient(90deg, #9571F6 0%, #583EF5 100%);
    box-shadow: 0 4px 16px 5px rgba(88, 62, 245, 0.13);
    color: $background;

    &:hover,
    &:focus,
    &:active {
      color: $background;
    }

    svg {
      fill: $background;
    }
  }
}
