@import './src/variables';

.houses-main-wrapper {
  position: relative;
  display: flex;

  .main {
    width: 100%;
    margin-left: 20px;
    margin-right: 20px;

    .main-content {
      display: flex;
    }
  }
}

.filters-bar-criteria-modal {
  .modal-title-container {
    padding-bottom: 5px !important;
  }
  .ant-modal-body {
    .criteria-modal-body {
      padding-top: 0;
    }
  }
  @media (max-width: 1550px) {
    width: 35% !important;
  }
}

.favorites-title {
  //margin: 110px 40px 0;
  margin: 40px 32px 0;
  font: 22px/27px $PB;
}

.favorites {
  .favorites-button {
    width: 40px;
    height: 40px;
    border-radius: 12px;
    background: $white;
    border: 1px solid $grey-light;

    .ant-btn:hover,
    .ant-btn:focus {
      border: 1px solid $grey-light;
    }

    svg {
      position: absolute;
      left: 30.12%;
      top: 32.44%;
      fill: $violet-light;
    }
  }

  .active {
    background: linear-gradient(90deg, #9571F6 0%, $violet 100%);

    &:focus,
    &:hover {
      background: linear-gradient(90deg, #9571F6 0%, #583EF5 100%);
    }

    svg {
      fill: $white;
    }
  }
}

.ant-table {
  overflow: hidden;
  border-radius: 24px;

  .ant-table-column-title {
    flex: 0 0;
  }
}

.houses {
  position: relative;

  .houses-header {
    position: absolute;
    padding-right: 10px;
    right: 0;
    z-index: 2;
  }
}

.list-content {
  margin: 0 !important;
  padding-left: 20px;
  padding-right: 20px;
}
.empty-button{
  .ant-btn {
    background: linear-gradient(90deg, #9571F6 0%, $violet 100%);
    color: $white;
    border-radius: 16px;
    font: 16px $PB;
    padding: 0;
    border: none;
    height: auto;
    transition: all 0.3s;

    svg {
      fill: $white;
      margin-left: 16px;
    }

    &:hover,
    &:focus {
      background-size: 300% 100%;
      background-position: 0 100%;
      background-image: linear-gradient(90deg, #9571F6 0%, $violet 100%);
      color: $white;
    }

    &:disabled {
      background: $grey-light2;
      color: $grey2;
      cursor: default;

      svg {
        fill: $grey2;
      }
    }

    .button__text {
      padding: 20px 44px;
    }
  }
}
