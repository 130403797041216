@import "src/_variables.scss";

.card-view-wrapper {
  min-height: 248px;
  .card-view {
    background: $white;
    border-radius: 16px;
    cursor: pointer;

    .card-view__price {
      position: absolute;
      left: 6px;
      bottom: 12px;
    }

    .status {
      display: flex;
      align-items: center;
      line-height: 16px;

      .property__type {
        font: 9px/16px $PSB;
        color: #A9A9C4;
        padding-right: 16px;
      }

      &-item {
        font: 9px/16px $PSB;
        padding-right: 16px;
      }

      &-days {
        font: 9px/16px $PSB;
        color: #A9A9C4;
        padding-left: 5px;
        svg {
          min-width: 14px;
          width: 14px;
          margin-right: 5px;
        }
      }
    }
    .new-home {
      position: absolute;
      top: 15px;
      padding: 2px 4px;
      right: 45px;
      color: #FFFFFF;
      font-weight: 700;
      font-size: 12px;
      line-height: 12px;
      font-style: normal;
      font-family: $PSB;
      text-transform: uppercase;
      background: linear-gradient(225.4deg, #FFCBA0 0%, #DD5789 48.12%, #9B5BE6 93.59%);
      border-radius: 4px;
    }
    &__photo {
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
      overflow: hidden;
      position: relative;

      .ant-image {
        display: block;
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;
        overflow: hidden;
      }

      img {
        object-fit: cover;
        object-position: 50% 50%;
      }
    }

    &__score-inner {
      position: relative;
      height: auto;
    }

    &__score {
      position: absolute;
      z-index: 1;
      top: 15px;
      left: 12px;

      .score-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
        width: 40px !important;
        border-radius: 29px;
        font-style: normal;
        font: 20px $PB;
        min-width: 40px;
      }
    }

    &__bottom-content {
      padding: 7px 12px 12px;
      .criteria {
        .accordion {
          margin-bottom: 12px;
          .card-tag {
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            width: 60px;
            min-width: 56px;
          }
        }
      }
      .address {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin-bottom: 8px;
        min-height: 32px;
        &-content {
          font: 12px/16px $PSB;
          color: $dark-blue;
          &.isViewed {
            color: $grey2;
          }
        }
      }
    }
  }
}

.card-tag {
  border-radius: 8px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  -webkit-font-smoothing: antialiased;
  font: 12px/14px $PSB;
  margin: 5px 6px 0;
  font-weight: 600;
  min-height: 24px;
  padding: 0 4px;
  min-width: 56px;
  //width: calc(25% - 12px);
  width: 56px;
  text-align: center;
  line-height: 1.29;
  white-space: break-spaces;
}

.price-tag {
  font: 14px/14px $PSB;
  width: calc(100% - 12px) !important;
}

.accordion {
  margin: 0 -6px;

  &:not(:last-child) {
    padding-bottom: 24px;
    margin-bottom: 24px;
    border-bottom: 1px solid blue;
  }

  &__arrow {
    width: 12px;
    min-width: 12px;
    margin: 0 auto;
    color: #A0A0A0;

    svg {
      transition: 0.3s;
    }
  }

  &__text {
    display: flex;
  }

  &__icon {
    width: 20px;
    min-width: 20px;
    height: 20px;
    margin-right: 4px;
  }

  &__title {
    font-weight: 500;
    font-size: 14px;
  }

  &__block {
    display: none;
    font-size: 12px;
  }

  &.is-active {
    .accordion__arrow {
      svg {
        transform: rotate(180deg);
      }
    }

    .accordion__block {
      display: flex;
      flex-wrap: wrap;
    }
  }
}

.favorites-heart {
  position: absolute;
  right: 15px;
  top: 15px;
  svg.active {
    path {
      fill: $violet;
    }
  }
}
