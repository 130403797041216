@import "src/_variables.scss";

.welcome-step-container {
  max-width: 60%;
  @media screen and (max-width: 992px) {
    max-width: 100%;
  }

  .welcome-step {
    &__image {
      margin: 0 auto 20px;
      width: 511px;
      height: 346px;

      display: flex;
      justify-content: center;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }

      @media screen and (max-width: 768px) {
        display: none;
      }
    }

    &__title {
      padding: 15px 0;
      font: 40px/44px $PSB;
      font-style: normal;
      font-weight: 600;
      text-align: center;
      color: $dark-blue;
      max-width: 900px;
      margin: 0 auto;

      @media (max-width: 1440px) {
        font: 36px/40px $PSB;
      }
      @media (max-width: 992px) {
        padding-top: 0;
        padding-bottom: 40px;
      }
      @media (max-width: 576px) {
        font: 24px/28px $PSB;
      }
    }
  }
}
