@import "src/_variables.scss";

.place-rating {
  display: flex;
  width: 40px;
  height: 40px;
  &.isMap {
    align-items: center;
    margin-right: 8px;
  }
  .circle {
    display: flex;
    height: 32px;
    width: 32px;
    margin: auto;
    border-radius: 50%;
    box-shadow: inset 0 4px 4px rgba(147, 157, 244, 0.17);
    justify-content: center;
    color: $white;
    font: 14px/17px $PB;
    &.isMap {
      height: 28px;
      width: 28px;
    }
    &.noRating {
      background: $dark-blue;
    }
    &__text {
      display: flex;
      text-align: center;
      align-items: center;
      .actual-rating {
        font: 14px/17px $PB;
        font-style: normal;
        font-weight: 700;
        color: $white;
        margin-right: 1px;
        &.isMap {
          font: 13px/15.83px $PB;
        }
      }
      .max-rating {
        font: 10px/12px $PB;
        font-style: normal;
        font-weight: 700;
        color: $grey-light;
        &.isMap {
          font: 8px/9.74px $PB;
          color: $grey;
        }
      }
    }
  }
  .number {
    font: 14px/17px $PB;
    font-style: normal;
    font-weight: 700;
    text-align: center;
    padding-right: 3px;
    &.isMap {
      font: 13px/15.83px $PB;
    }
  }
  .star-icon {
    width: 14px;
    height: 14px;
    color: $yellow-light;
    margin-top: 1px;
    &.isMap {
      width: 13px;
      height: 13px;
      margin-top: 0;
    }
  }
}
