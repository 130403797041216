@import "src/_variables.scss";

.react-images__view-image {
  height: 85vh !important;
}
.react-images__footer__count, .react-images__footer__count--isModal {
  padding-right: 100px !important;
}
//.react-images__footer__count, .react-images__footer__count--isModal {
//  margin-right: 80px !important;
//}
// These classes has static names, it looks like they are not autogenerated so we can use it to override styles
.css-xfk84m {
  margin-right: 45px;
}
.css-1h82jk3 {
  margin-left: 45px;
}
.sc-hKMtZM, .sc-eCYdqJ {
  border-radius: 12px;
}
.sc-gsnTZi {
  border: none !important;
}
.sc-bczRLJ {
  width: 100% !important;
}
.sc-dkzDqf + .sc-dkzDqf {
  margin-left: 5px !important;
}
.sc-gsnTZi + .sc-gsnTZi {
  margin-top: 5px !important;
}

.photos-container {
  display: flex;
  position: relative;
  min-height: 50px;
  .image-list {
    margin: 0;
    width: 100%;
    .image-list-item {
      cursor: pointer;
      .house-detail-image {
        border-radius: 16px;
      }
    }
  }
  .all-photos-btn {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 1;
    margin: 16px;
    background: #F4F7FC;
    border: 1px solid #1B235E;
    box-shadow: 0px 6px 24px rgba(0, 0, 0, 0.08);
    border-radius: 12px;
    .ant-btn {
      border: none;
      border-radius: 12px;
      width: 144px;
      height: 38px;
      .button__text {
        font: 14px $PR;
        font-style: normal;
        font-weight: 600;
        text-align: center;
        color: #1B235E;
      }
    }
  }
}
