@import "src/variables";

.days-number {
  font: 10px/16px $PSB;
  color: $grey2;
  padding-left: 9px;
  > span {
    display: flex;
    align-items: center;
  }
  svg {
    min-width: 12px;
    width: 12px;
    margin-right: 2px;
  }
}
