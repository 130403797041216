@import "src/_variables.scss";

.priority-criteria-step-container {
  .priority-step {
    &__title {
      display: block;
      padding: 50px 0 30px;
      font: 40px/44px $PSB;
      font-style: normal;
      text-align: center;
      color: $dark-blue;

      @media (max-width: 1440px) {
        font: 36px/40px $PSB;
      }

      &__text {
        padding: 10px 0;
      }

      .priority_step-subtitle {
        display: block;

        &__text {
          font: 20px/32px $PR;
          color: $grey;

          @media (max-width: 1440px) {
            font: 18px/28px $PR;
          }
        }
      }
    }

    &__criteria_column {
      display: flex;
      width: max-content;
      align-items: center;
      font-weight: 600;
      font-size: 12px;

      &_icon {
        width: 24px;
        min-width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 12px;
      }
    }

    &__content {
      width: 720px;
      margin: auto;

      .icon-button-wrapper .icon-button {
        max-width: 150px;
      }

      .criteria-row {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
        justify-content: center;
      }

      .criteria-column {
        .icon-button-wrapper .icon-button {
          margin: 10px auto;
          width: 150px;
          justify-content: center;
        }

        .priority-criteria-button-wrapper {
          text-align: center;

          .icon-button {
            max-width: 155px;
            width: 100%;
            height: auto;
            background: linear-gradient(180deg, $background 0%, $white 100%);
            box-shadow: 0px 6px 10px rgba(161, 181, 216, 0.22), inset 0px 4px 4px rgba(147, 157, 244, 0.17);
            border-radius: 16px;
            border: none;
            padding: 0;
            margin-bottom: 16px;

            &:hover,
            &:focus {
              background: linear-gradient(180deg, $background 0%, $white 100%);
            }

            &[disabled] {
              background: linear-gradient(180deg, $background 0%, $white 100%);
              box-shadow: inset 0px 4px 4px rgba(147, 157, 244, 1);
              opacity: 0.17;
              cursor: default;
              pointer-events: none;

              .icon-button-content {
                &__icon {
                  box-shadow: inset 0px 4px 4px rgba(147, 157, 244, 1);
                }
              }
            }

            &.button-fill {
              background: linear-gradient(#DDF2FF 10%, #C7C6FF 100%);
            }
          }

          .icon-button-content {
            position: relative;
            display: flex;

            &__icon {
              display: flex;
              justify-content: flex-end;
              align-items: center;
              min-width: 56px;
              width: 56px;
              height: 56px;
              border-radius: 16px;
              pointer-events: none;

              &:hover {
                background: linear-gradient(180deg, #DDF2FF 0%, #DEDDFF 100%);
                box-shadow: 0px 6px 10px rgba(161, 181, 216, 0.22), inset 0px 4px 4px rgba(147, 157, 244, 0.17);
              }
            }

            &__badge-icon {
              position: absolute;
              top: 40px;
              right: 0;
            }

            &__message {
              margin: auto;
              padding: 7px 11px 7px 6px;
              font: 12px/14px $PSB;
              color: $dark-blue;
              white-space: normal;
              text-align: left;
              pointer-events: none;
            }
          }
        }
      }

      .ant-table-thead>tr>th {
        color: #908DB9;
        font-weight: 700;
        font-size: 10px;
        text-align: center;
        background: transparent;
        border-bottom: none;
      }

      .ant-table-tbody>tr>td {
        border-bottom: none;
        vertical-align: middle;
        text-align: center;
        padding: 16px 8px;
        width: 100px;

        &:first-child {
          text-align: left;
          padding-left: 48px;
          width: auto;
        }
      }

      .ant-table-tbody>tr:nth-child(odd)>td {
        background: #FAFAFF;
      }

      .ant-table-content {
        max-height: 284px;
        overflow-y: auto;
        scrollbar-width: thin;
        scrollbar-color: #D0D1E1 #f5f5f5;

        &::-webkit-scrollbar {
            height: 6px;
            width: 6px;
        }
        &::-webkit-scrollbar-track {
            background: #f5f5f5;
        }
          
        &::-webkit-scrollbar-thumb {
            background-color: #D0D1E1;
            border-radius: 4px;
            border: 0;
        }
      }

      .ant-table-thead {
        position: sticky;
        top: 0;
        z-index: 10;
        background: #ffffff;
      }

      .ant-radio-inner {
        width: 20px;
        height: 20px;
        background: #F4F7FC;
        box-shadow: inset 0px 2px 4px rgba(149, 113, 246, 0.15);
        border: none;

        &::after {
          top: 2px;
          left: 2px;
          display: block;
          width: 16px;
          height: 16px;
          background: linear-gradient(90deg, #9571F6 0%, #9571F6 0.01%, #583EF5 100%);
          box-shadow: inset 0px 2px 4px rgba(149, 113, 246, 0.15);
        }
      }

      .ant-radio-checked .ant-radio-inner {
        box-shadow: 0px 4px 16px 5px rgba(88, 62, 245, 0.13);
      }
    }
  }
}