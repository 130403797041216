@import "src/_variables.scss";

.almost-there-step-container {
  width: 30%;
  padding: 150px 0;
  margin: auto;
  @media (max-width: 1440px) {
    padding: 50px 0;
    width: 40%;
  }

  .almost-there-step {
    &__title {
      padding: 15px 0;
      font: 40px/44px $PSB;
      font-style: normal;
      font-weight: 600;
      text-align: center;
      color: #1B235E;
      @media (max-width: 1440px) {
        font: 36px/40px $PSB;
      }
    }
    
    &__subtitle {
      padding: 15px 0;
      font: 20px/32px $PR;
      font-style: normal;
      font-weight: 600;
      text-align: center;
      color: $grey;
      @media (max-width: 1440px) {
        font: 18px/28px $PR;
      }
    }
  }
}
