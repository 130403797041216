@import './src/variables';

.logout-modal {
  width: 424px !important;
  height: 636px !important;

  .ant-modal-content {
    border-radius: 24px;
  }
  .ant-modal-body {
    padding: 0;
  }
}

.logout-modal-container {
  width: 424px;
  height: 636px;

  .logout-image-container {
    background: #F4F7FC;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
    padding-top: 29px;

    .logout-image {
      display: flex;
      background: url("/images/logout.png") no-repeat;
      height: 343px;
    }
  }

  .logout-content {
    height: 264px;
    padding: 40px;

    &__title {
      line-height: 28px;
      font-size: 24px;
      font-family: 'Proxima Nova Bold';
      color: #1B235E;
    }
    &__description {
      margin-top: 16px;
      line-height: 24px;
      font-size: 16px;
      font-family: 'Proxima Nova Regular';
      color: #1B235E;
    }
    &__buttons {
      margin-top: 52px;
      display: flex;
      justify-content: space-between;

      .ant-btn {
        font-size: 18px;
        font-family: 'Proxima Nova Bold';
        width: 160px;
        height: 60px;
      }
    }
  }
}


.general-modal {
  width: 553px;
  height: 372px;

  .general-content {
    padding: 42px;

    &__title {
      font:24px $PSB;
      padding-bottom: 16px;
    }

    &__description {
      font: 16px $PR;
      padding-bottom: 16px;
    }

    &__buttons {
      display: grid;
      grid-template-columns: 45% 45%;
      justify-content: space-around;

      .ant-btn {
        width: 100%;
      }
    }
  }
  .general-content__centered {
    display: flex;
    flex-direction: column;
    align-items: center;

    .general-content__title {
      margin-top: 45px;
    }

    .general-content__description {
      text-align: center;
      width: 384px;
    }

    button {
      margin-top: 45px;
      width: 426px;
    }
    .form-input {
      width: 426px;
    }
  }
  .general-image-container {
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
    background-color: #F4F7FC;
  }

  .general-modal-close {
    button {
      background: #F4F7FC;
      box-shadow: 0px 6px 32px rgba(0, 0, 0, 0.02);
      border-radius: 12px;
    }
  }

  .ant-modal-content {
    border-radius: 24px;
  }
  .ant-modal-body {
    padding: 0;
  }
}


.modal-close {
  .ant-btn {
    position: absolute;
    top: 24px;
    right: 24px;
    background: white;
    box-shadow: 0px 6px 32px rgba(0, 0, 0, 0.02);
    border-radius: 12px;
    border: none;
    padding: 17px;
    height: auto;
    line-height: 0;

    svg {
      fill: $grey;
      width: 10px;
      height: 10px;
    }
  }
}

