@import "src/_variables.scss";

.details-map {
  display: flex;
  height: 450px;
  width: 100%;
  margin-bottom: 22px;

  .map-view-wrapper {
    padding-top: 0;
    margin-top: 0;
    .map-view {
      width: 100%;
      height: 100%;
    }
    .google-container-wrapper {
      margin-top: 0;
      position: relative;
      .google-container {
        border-radius: 0;
        height: 450px;
        .gm-style .gm-style-iw-t::after {
          display: none;
        }

        .gm-style-iw-a {
          margin-top: -50px;
        }

        .gm-style-iw.gm-style-iw-c {
          border-radius: 16px;
          padding: 0;
          .gm-style-iw-d {
            overflow: inherit !important;
          }
          .gm-ui-hover-effect {
            display: none !important;
          }
        }

        .map-marker-container {
          margin-top: -48px;
          margin-left: -20px;
        }

        .place-info-card {
          height: 53px;
          background: $white;
          box-shadow: 0 6px 24px rgba(0, 0, 0, 0.08);
          display: flex;
          width: 100%;
          padding: 10px 8px;
          align-items: center;
        }
      }
    }
  }
}
