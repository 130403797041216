@import './src/variables';

.base-layout {
  height: 100%;
  .ant-layout-sider {
    transition: all 0.3s;
  }
}

.name {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100px;
}

.sidebar:not(.ant-layout-sider-collapsed) {
  min-width: 234px !important;
}

.ant-layout-sider-collapsed.sidebar {
  min-width: 124px !important;

  .sidebar-layout__name {
    margin-left: 15px;

    .name {
      display: none;
    }
  }

  .ant-menu-item .ant-menu-item-icon {
    min-width: 75px;
  }

  .ant-menu.ant-menu-inline-collapsed>.ant-menu-item {
    padding-left: 15px;
  }

  .ant-menu.ant-menu-inline-collapsed > .ant-menu-item .ant-menu-item-icon + span {
    transition: opacity 0s;
  }
}

.sidebar-layout {
  height: 100%;
  display: flex;

  .sidebar__menu {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding-bottom: 40px;
    &-deliver {
      flex-grow: 1;
    }
  }

  .ant-layout-sider-children {
    min-height: 100vh;
    background: $white;
    display: flex;
    flex-direction: column;
  }

  .sidebar-children {
    width: 100%;
    background: $background;
    min-height: 100vh;
  }

  .ant-menu {
    padding-top: 75px;

    .ant-menu-item {
      display: flex;
      align-items: center;
      width: 87%;
      margin: auto;
      background: $background;
      border-radius: 12px;
      font: 14px/16px $PSB;
      color: $grey;
      background: transparent;

      svg {
        vertical-align: middle;
        fill: $grey3;
        width: 20px;
        min-width: 20px;
        height: 20px;
      }

      &:hover,
      &:focus {
        color: $dark-blue;
      }

      &:last-child {
        svg {
          fill: $orange;
        }

      }
    }

    .ant-menu-item-selected,
    &:not(.ant-menu-horizontal) .ant-menu-item-selected {
      background: $background;
      color: $dark-blue;

      svg {
        fill: $violet-light;
      }
    }
    .sign-up {
        &:last-child {
          svg {
            transform: rotate(180deg);
            fill: #583EF5;
          }
      }
      }
  }


  .ant-menu-vertical .ant-menu-item:not(:last-child),
  .ant-menu-vertical-left .ant-menu-item:not(:last-child),
  .ant-menu-vertical-right .ant-menu-item:not(:last-child),
  .ant-menu-inline .ant-menu-item:not(:last-child) {
    margin-bottom: 22px;
  }

  &__name {
    display: flex;
    justify-content: center;
    font: 16px/19px $PSB;
    align-items: center;
    color: $dark-blue;
    margin-bottom: 110px;
    margin-left: -30px;

    .initials {
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 44px;
      width: 44px;
      height: 44px;
      background: $violet-light;
      box-shadow: 0px 6px 32px rgba(0, 0, 0, 0.02);
      border-radius: 12px;
      color: $white;
      font: 19px/23px $PSB;
      margin-right: 16px;
    }
  }
}
