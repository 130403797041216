@import "src/_variables.scss";

.icon-wrapper {
  min-width: 28px;
  height: 28px;
  background: $white;
  box-shadow: inset 0 4px 4px rgba(147, 157, 244, 0.17);
  border-radius: 50%;
  .icon {
    display: flex;
    margin: auto;
    width: 16px;
    //max-height: 16px;
    height: 100%;
  }
}
