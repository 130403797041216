@import "src/_variables.scss";

.all-photos-container {
  padding: 10px 64px 32px 64px;

  .sc-dkzDqf + .sc-dkzDqf {
    margin-left: 24px !important;
  }
  .sc-gsnTZi + .sc-gsnTZi {
    margin-top: 24px !important;
  }
}
