@import "src/variables";

.second-step {
  width: 720px;
  margin: 20px auto 57px;
  .ant-table {
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
  }

  &__criteria_column {
    display: flex;
    width: max-content;
    align-items: center;
    font-weight: 600;
    font-size: 12px;

    &_icon {
      width: 24px;
      min-width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 12px;
    }
  }

  .ant-table-thead > tr > th {
    color: $grey;
    font-weight: 700;
    font-size: 10px;
    text-align: center;
    background: transparent;
    border-bottom: none;
    &:last-child {
      padding-right: 58px !important;
    }
  }

  .ant-table-tbody > tr > td {
    border-bottom: none;
    vertical-align: middle;
    text-align: center;
    padding: 16px 8px;
    width: 74px;

    &:first-child {
      padding-left: 86px !important;
    }
    &:last-child {
      padding-right: 58px !important;
    }
  }

  .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    display: none;
  }

  .ant-table-tbody>tr:nth-child(odd)>td {
    background: #FAFAFF;
  }

  .ant-table-content {
    max-height: 430px;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #D0D1E1 #f5f5f5;

    &::-webkit-scrollbar {
      height: 6px;
      width: 6px;
    }
    &::-webkit-scrollbar-track {
      background: #f5f5f5;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #D0D1E1;
      border-radius: 4px;
      border: 0;
    }
  }

  .ant-table-thead {
    position: sticky;
    top: 0;
    z-index: 10;
    background: #ffffff;
  }

  .ant-radio-inner {
    width: 20px;
    height: 20px;
    background: #F4F7FC;
    box-shadow: inset 0 2px 4px rgba(149, 113, 246, 0.15);
    border: none;

    &::after {
      top: 2px;
      left: 2px;
      display: block;
      width: 16px;
      height: 16px;
      background: linear-gradient(90deg, #9571F6 0%, #9571F6 0.01%, #583EF5 100%);
      box-shadow: inset 0 2px 4px rgba(149, 113, 246, 0.15);
    }
  }

  .ant-radio-checked .ant-radio-inner {
    box-shadow: 0 4px 16px 5px rgba(88, 62, 245, 0.13);
  }
}
