@import './src/variables';


.range-histogram {
  margin-top: 10px;
  width: 100%;
  height: 100px;

  &__numbers {
    display: flex;
    font:14px $PSB;

    >div {
      flex: 1 1 (100%/8);
      text-align: center;
    }
  }
}