@import "src/_variables.scss";

.neighborhood-info-container {
  display: block;
  padding-left: 64px;
  .neighborhood-info-criteria {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 10px 59px 10px 0;

    .criteria-card-wrapper {
      position: relative;
      margin: 0 10px 10px 0;
      .criteria-card {
        background: linear-gradient(180deg, #F4F7FC 0%, #FFFFFF 100%);
        box-shadow: inset 0 4px 4px rgba(147, 157, 244, 0.17);
        border-radius: 24px;
        width: 104px;
        height: 148px;
        padding: 12px 10px 16px 16px;
        position: relative;
        z-index: 1;
        &__icon-wrapper {
          width: 40px;
          height: 40px;
        }
        &__icon {
          width: 20px;
        }
        &__pill {
          width: 72px;
          height: 28px;
          bottom: 0;
          font: 16px $PSB;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 8px;
          position: absolute;
          margin-bottom: 16px;
        }
        &__title {
          font-style: normal;
          font-weight: 700;
          font: 14px/16px $PB;
          color: $dark-blue;
          padding-top: 8px;

          &.is_long_title {
            font: 13px/16px $PB;
          }
        }
      }

      .criteria-card-shadow-wrapper {
        display: flex;
        justify-content: center;
        width: 100%;
        height: 13px;
        position: absolute;
        bottom: 0;
        .criteria-card-shadow {
          background: $grey3;
          filter: blur(21px);
          border-radius: 16px;
          width: 72px;
          height: 13px;
        }
      }
    }
    .criteria-card-wrapper:last-child {
      margin-right: 0;
    }
  }
  .neighborhood-info-places {
    display: flex;
    overflow-x: scroll;
    .places-card-wrapper {
      position: relative;
      margin: 0 16px 30px 0;
      .places-card-container {
        height: 239px;
        width: 272px;
        border-radius: 24px;
        background: linear-gradient(180deg, #F4F7FC 0%, $white 100%);
        box-shadow: inset 0 4px 4px rgba(147, 157, 244, 0.17);
        padding: 24px 16px;
        position: relative;
        z-index: 1;
        .places-card {
          &.noData {
            background: rgba(255, 255, 255, 0.53);
            filter: blur(8px);
            pointer-events: none;
            user-select: none;
          }
          &__title {
            color: $dark-blue;
            font: 18px/22px $PB;
            font-style: normal;
            font-weight: 700;
          }
          &__description {
            display: block;
            width: 100%;
            height: 100%;
            padding-top: 16px;
            .place-info {
              display: flex;
              justify-content: space-between;
              &__left {
                display: flex;
                padding-bottom: 16px;
                &__icon {
                  padding-right: 12px;
                }
              }
            }
          }
        }
      }
      .places-card-shadow-wrapper {
        display: flex;
        justify-content: center;
        width: 100%;
        height: 13px;
        position: absolute;
        bottom: 0;
        .places-card-shadow {
          background: $grey3;
          filter: blur(21px);
          border-radius: 16px;
          width: 244px;
          height: 13px;
        }
      }
      .places-card-no-data {
        font: 14px/16px $PSB;
        font-style: normal;
        font-weight: 600;
        text-align: center;
        color: $grey;
        text-shadow: 0 4px 28px #FFFFFF, 0 4px 40px #FFFFFF;
        position: absolute;
        top: 40%;
        z-index: 2;
        width: 100%;
        height: 16px;
      }
    }
    //.places-card-wrapper:last-child {
    //  margin-right: 0;
    //}
  }
  .neighborhood-info-title {
    color: $dark-blue;
    font: 22px/27px $PSB;
    font-style: normal;
    font-weight: 700;
    padding: 10px 0;
  }
}
