@import "src/_variables.scss";

.schedule-tour-button-wrapper {
  .schedule-tour-button {
    .ant-btn {
      background: linear-gradient(90deg, #9571F6 0%, $violet 100%);
      color: $white;
      border-radius: 16px;
      font: 16px $PB;
      padding: 0;
      border: none;
      height: 50px;
      width: 160px;
      transition: all 0.3s;
      svg {
        fill: $white;
        margin-left: 16px;
      }
      &:hover,
      &:focus {
        background-size: 300% 100%;
        background-position: 0 100%;
        background-image: linear-gradient(90deg, #9571F6 0%, $violet 100%);
        color: $white;
      }
      &:disabled {
        background: $grey-light2;
        color: $grey2;
        cursor: default;

        svg {
          fill: $grey2;
        }
      }
      .button__text {
        justify-content: center;
      }
    }
  }
}
