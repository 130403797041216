@import "src/variables";

.house-marker {
  display: block;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  margin-top: -23px;
  margin-left: -20px;
  &__dot {
    margin-top: 1px;
  }
  &__label {
    width: 38px;
    height: 14px;
    font: 9px/14px $PSB;
    font-style: normal;
    font-weight: 600;
    text-align: center;
    background-color: $background;
    color: $dark-blue;
    border-radius: 10px;
  }
}

.current-page-marker {
  position: absolute;
  top: -58px;
  left: -35px;
  cursor: pointer;
  display: block;
  height: 100px;

  &__dot {
    display: flex;
    color: $white;
    font: 21.7105px/20px $PSB;
    font-style: normal;
    font-weight: 600;
    text-align: center;
    border-radius: 50%;
    border: 1.44737px solid #FFFFFF;
    box-shadow: 0 5.78947px 5.78947px rgba(0, 0, 0, 0.25);
    width: 44px;
    height: 42.37px;
    margin: 5px auto 0;
    justify-content: center;
    align-items: center;
  }
  &__label {
    display: flex;
    background-color: $background;
    border: 2px solid $background;
    border-radius: 15px;
    color: $dark-blue;
    font: 16px/20px $PSB;
    font-style: normal;
    font-weight: 600;
    min-width: 66px;
    height: 23px;
    padding: 0 5px;
    align-items: center;
    justify-content: center;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  }
}
