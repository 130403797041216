@import "src/_variables.scss";

.other-criteria-step-container {
  .other-criteria {
    &__title {
      display: block;
      padding: 64px 0 36px;
      font: 40px/44px $PSB;
      font-style: normal;
      text-align: center;
      color: $dark-blue;

      @media (max-width: 1440px) {
        font: 36px/40px $PSB;
      }

      &__text {
        padding: 10px 0;
      }

      .other-criteria-subtitle {
        display: block;

        &__text {
          font: 20px/32px $PR;
          color: $grey;

          @media (max-width: 1440px) {
            font: 18px/28px $PR;
          }
        }
      }
    }
  }

  .other-criteria-step {
    &__content {
      display: flex;
      width: 100%;
      justify-content: center;
      flex-wrap: wrap;
      margin: 0 auto;

      .criteria-row {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        margin: auto;
        width: 100%;

        .criteria-column {
          padding: 0 8px 16px;
          width: 138px;
          min-width: 138px;

          .criteria-button-wrapper .icon-button-content__message {
            padding-right: 15px;
          }
        }
      }
    }
  }
}