@import './src/variables';
@import './src/global_styles';

.main-wrapper {
  position: relative;
  display: flex;

  .main {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    .icon-button-wrapper {
      zoom: 0.9;
    }

    .main-content {
      display: flex;
    }

    .main-header {
      display: flex;
      align-items: center;
      margin-top: 45px;
      margin-bottom: 10px;

      &__title {
        font: 22px/27px $PB;
        color: $dark-blue;
        margin-bottom: 30px;
      }

      &__results {
        padding-left: 24px;
        padding-top: 5px;
        font: 16px $PSB;
        color: #908DB9;
      }

      &__left {
        margin-left: auto;

        .button-see-matches.disabled {
          .ant-btn {
            background: $grey-light2;
            color: $grey2;
            cursor: default;

            &:hover,
            &:focus {
              background-size: 300% 100%;
              background-position: 0 100%;
              background-image: none;
              color: $grey2;
            }
            svg {
              fill: $grey2;
            }
          }
        }
        .button-see-matches {
          .ant-btn {
            background: linear-gradient(90deg, #9571F6 0%, $violet 100%);
            color: $white;
            border-radius: 16px;
            font: 16px $PB;
            padding: 0;
            border: none;
            height: auto;
            transition: all 0.3s;

            svg {
              fill: $white;
              margin-left: 16px;
            }

            &:hover,
            &:focus {
              background-size: 300% 100%;
              background-position: 0 100%;
              background-image: linear-gradient(90deg, #9571F6 0%, $violet 100%);
              color: $white;
            }

            &:disabled {
              background: $grey-light2;
              color: $grey2;
              cursor: default;

              svg {
                fill: $grey2;
              }
            }

            .button__text {
              padding: 20px 44px;
            }
          }


        }
      }
    }

    .criteria-wrapper {
      display: flex;
      padding: 32px;
      margin: 10px 0;
      width: 100%;
      background: $white;
      border-radius: $border-radius-container;

      .coming-soon {
        margin-left: auto;

        &__title {
          color: $grey2;
          font: 16px/19px $PSB;
          margin-bottom: 12px;
        }

        &__content {
          display: grid;
          grid-template-columns: 50% 50%;
        }
      }
    }
  }

  .criteria-content {
    &__content {
      display: flex;
      flex-wrap: wrap;
    }

    .criteria-title {
      font: 16px/19px $PSB;
      margin-bottom: 12px;
      color: #1B235E;
    }
  }

  .filters-wrapper {
    min-width: 48%;
    margin: 10px;
    padding: 24px;
    background: $white;
    border-radius: $border-radius-container;
  }
}

.criteria-error-message {
  position: absolute;
  padding: 12px 30px;
  width: 100%;
  font: 16px/20px $PB;
  color: $white;
  top: 0;
  left: 0;
  right: 0;
  background: $red;

  &__icon {
    vertical-align: middle;
    width: 16px;
    height: 16px;
    margin-right: 13px;
  }

  .ant-btn {
    position: absolute;
    background: transparent;
    width: 13px;
    height: 13px;
    border: none;
    padding: 0;
    right: 24px;
    top: 15px;

    &:hover,
    &:focus,
    &:active {
      background: transparent;
    }

    svg {
      fill: $white;
    }
  }
}

.bubble-cluster-container {
  height: 416px;
  border-radius: 25px;
  //margin: 10px !important;
  background: white;

  &__title {
    position: relative;
    &-message {
      position: absolute;
      top: 25px;
      left: 30px;
      font: 16px/19px "Proxima Nova Semibold";
      color: #1B235E;
    }
  }

  .bubble-cluster {
    height: 100%;
    display: flex;
    justify-content: center;

    .bubble-tooltip {
      position: absolute;
      visibility: hidden;
      border-radius: 4px;
      background: #1B235E;
      color: white;
      padding: 1px 5px 1px 5px;
      .bubble-tooltip-key {
        font-weight: bold;
        text-transform: capitalize;
      }
    }
    .bubble-svg {
      max-width: 1500px;
      height: 416px;
      width: 100%;
    }

    &__empty {
      margin: auto;
      &-image {
        display: flex;
        justify-content: center;
      }
      &-text {
        color: #A9A9C4;
        font-size: 18px;
        font-family: "Proxima Nova Semibold";
      }
    }
  }
}