.remote-submit-button-wrapper {
  .btn-default-position {
    // position: absolute;
    // bottom: 0;
    // right: 0;
    position: static;

    .ant-form-item {
      margin-bottom: 0;
    }
  }
}
