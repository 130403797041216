@import "src/variables";

.map-info-window {
  background: $white;
  border-radius: 24px;
  cursor: pointer;
  width: 240px;

  .new-home {
    position: absolute;
    top: 15px;
    padding: 2px 4px;
    right: 45px;
    color: #FFFFFF;
    font-weight: 700;
    font-size: 12px;
    line-height: 12px;
    font-style: normal;
    font-family: $PSB;
    text-transform: uppercase;
    background: linear-gradient(225.4deg, #FFCBA0 0%, #DD5789 48.12%, #9B5BE6 93.59%);
    border-radius: 4px;
  }
  &__photo {
    overflow: hidden;
    position: relative;

    .ant-image {
      display: block;
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
      overflow: hidden;
    }

    img {
      object-fit: cover;
      object-position: 50% 50%;
    }
  }

  &__score {
    position: absolute;
    z-index: 1;
    top: 15px;
    left: 15px;

    .score-container {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;
      width: 40px !important;
      border-radius: 29px;
      font-style: normal;
      font: 20px $PB;
      min-width: 40px;
    }
  }

  &__bottom-content {
    padding: 8px 15px 15px;
    .criteria {
      display: flex;
      justify-content: center;
      align-items: center;
      &__card-tag {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        font: 10px/12px $PSB;
        font-style: normal;
        min-width: 48px;
        height: 24px;
        margin-right: 5px;
      }
      &__card-tag:last-child {
        margin-right: 0;
      }
    }
    .address {
      display: flex;
      justify-content: space-between;
      margin-bottom: 6px;
      align-items: center;
      font: 12px/20px $PSB;
      color: $dark-blue;
    }
  }
}
