@import "src/variables";

.criteria-sorting {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  background: $white;
  padding: 10px 24px;

}
.map-view-wrapper {
  width: 100%;
  height: 100%;
  min-height: 450px;

  .map-view-container {
    display: flex;
    height: 100%;
    .map-wrapper {
      position: relative;
      width: calc(100% - 505px); // 60%
      height: calc(100vh - 240px);
      .map-container {
        width: 100%;
        height: 100%;
        border-bottom-right-radius: 16px;
        &.isFavorites {
          border-top-right-radius: 16px;
        }

        .gm-style .gm-style-iw-t::after {
          display: none;
        }

        .gm-style-iw-a {
          margin-top: -50px;
        }

        .gm-style-iw.gm-style-iw-c {
          border-radius: 16px;
          padding: 0;
          .gm-style-iw-d {
            overflow: inherit !important;
          }
          .gm-ui-hover-effect {
            display: none !important;
          }
        }

        .gm-style-iw-tc {
          display: none;
        }

        div {
          &:focus {
            outline: none;
          }
        }
      }
    }
  }
}
