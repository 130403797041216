@import "src/variables";

.list-house-container {
  width: 505px; // 40%
  min-width: 505px;

  .criteria-sorting {
    &.isFavorites {
      border-top-left-radius: 16px;
    }
  }

  .list-house {
    padding: 0 25px 25px;
    background: #FFFFFF;
    height: calc(100vh - 358px);
    overflow-y: auto;

    scrollbar-width: thin;
    scrollbar-color: #D0D1E1 #f5f5f5;
    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }
    &::-webkit-scrollbar-track {
      background: #f5f5f5;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #D0D1E1;
      border-radius: 4px;
      border: 0;
    }

    .favorites .favorites-button {
      width: 40px;
      height: 40px;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        position: static;
      }
    }
  }

  .list-house-pagination {
    border-bottom-left-radius: 16px;
    height: 82px;
    background: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;

    .ant-pagination {
      display: flex;
    }

    .ant-pagination-prev,
    .ant-pagination-next,
    .ant-pagination-jump-prev,
    .ant-pagination-jump-next {
      box-shadow: 0 6px 32px rgba(0, 0, 0, 0.02);

      .ant-pagination-item-link {
        background: $background;
        box-shadow: 0 6px 32px rgba(0, 0, 0, 0.02);
        border-radius: 12px;
        border: none;
        font-size: 16px;
        width: 44px;
        height: 44px;
        min-width: 44px;

        .anticon svg {
          fill: $violet;
        }
        .ant-pagination-item-ellipsis {
          margin-top: 6px;
          color: $dark-blue;
        }
        &:disabled {
          .anticon svg {
            fill: $grey3;
          }
        }
      }
    }

    .ant-pagination-item {
      display: flex;
      align-items: center;
      justify-content: center;
      background: transparent;
      border: none;
      width: 44px;
      height: 44px;
      min-width: 44px;
      a {
        color: $dark-blue;
        font: 18px $PSB;
      }
    }

    .ant-pagination-item-active a {
      color: $violet;
    }
  }

  .list-house__card {
    border-radius: 16px;
    float: left;
    width: 48%;
    box-shadow: 0 6px 10px rgba(161, 181, 216, 0.22), inset 0px 4px 4px rgba(147, 157, 244, 0.17);
    margin-top: 16px;
    &:nth-child(even) {
      margin-left: 16px;
    }
  }

  .list-house-header {
    display: flex;
    cursor: pointer;
    align-items: center;

    &__sorting {
      display: flex;
      align-items: center;
      justify-content: center;
      background: none;
      font-weight: 600;
      height: 16px;
      color: $grey;
      font: 14px/16px $PR;
      cursor: pointer;
      white-space: nowrap;

      &-icon {
        transition: 0.3s;
        display: inline-flex;

        &.is-active {
          transform: rotate(180deg);
        }
      }
    }
  }

  .ant-divider-horizontal {
    margin-bottom: 7px;
  }
}
