@import "src/_variables.scss";

.onboarding-layout-container {
  background: $white;
  position: relative;
  padding: 32px;
  height: 100vh;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #D0D1E1 #f5f5f5;

  &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
  }
  &::-webkit-scrollbar-track {
      background: #f5f5f5;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #D0D1E1;
    border-radius: 4px;
    border: 0;
  }

  @media (max-width: 768px) {
    padding: 24px;
    display: flex;
    flex-direction: column;
  }

  .logo {
    display: flex;
    justify-content: center;
    padding: 20px 0;
    width: 100%;

    @media (max-width: 1440px) {
      padding: 0 0 40px;
    }
    @media (max-width: 992px) {
      justify-content: flex-start;
    }
    @media screen and (max-width: 576px) {
      a {
        display: inline-block;
        width: 94px;
        img {
          max-width: 100%;
          height: auto;
        }
      }
    }

    svg {
      width: 176px;
      height: 55px;

      @media (max-width: 1440px) {
        width: 160px;
        height: 50px;
      }
    }
  }

  .children-container {
    min-height: 50vh;
    padding-top: 20px;

    &.extended {
      min-height: 70vh;
    }

    @media (max-width: 768px) {
      padding-top: 0;
      min-height: 0;
      flex-grow: 1;
    }
  }

  .layout-footer {
    padding: 20px 0;
    @media (max-width: 768px) {
      padding-bottom: 0;
    }

    .layout-buttons {
      display: block;

      &.extended {
        display: flex;
        justify-content: center;
        width: 720px;
        margin: auto;
        flex-direction: row-reverse;

        .next-button {
          width: 410px;
          padding-left: 10px;
          padding-right: 10px;
        }

        .back-button {
          width: 247px;
          padding-left: 10px;
          padding-right: 10px;
        }
      }

      .next-button {
        margin: 0 auto;
        max-width: 390px;
        padding: 12px 0;

        .ant-btn {
          background: linear-gradient(90deg, #9571F6 0%, $violet 100%);
          border-radius: 16px;
          color: $white;
          width: 100%;
          padding: 15px;
          height: 60px;
          border: none;
          transition: all .4s ease-in-out;

          @media (max-width: 576px) {
            padding: 11px 15px;
            height: 44px;
          }

          &:hover,
          &:focus {
            background-size: 300% 100%;
            background-position: 0 100%;
            background-image: linear-gradient(90deg, #9571F6 0%, $violet 100%);
            border: none;
            color: $white;
          }

          &:after {
            display: none;
          }

          &:disabled {
            background: $grey-light;
          }

          .button__text {
            justify-content: center;
            font: 18px/22px $PB;
            @media (max-width: 576px) {
              font-size: 16px;
            }
          }

          svg {
            fill: $white;
            width: 16px;
            height: auto;
          }

          &:disabled {
            cursor: default;
            background: $grey-light;
            color: $grey2;

            svg {
              fill: $grey2;
            }
          }
        }
      }

      .back-button {
        margin: 0 auto;
        max-width: 390px;
        padding: 12px 0;

        .ant-btn {
          border: none;
          width: 100%;
          height: 60px;
          color: $grey;
          font: 18px/24px $PB;
          padding: 16px;
          background: $white;
          box-shadow: 0px 6px 24px rgba(0, 0, 0, 0.08);
          border-radius: 16px;
          cursor: pointer;
          transition: all 0.3s;

          &:after {
            display: none;
          }

          &:disabled {
            background: $grey-light;
          }

          .button__text {
            justify-content: center;
            font: 18px/22px $PB;
          }
        }

        .back-button-text {
          display: flex;
          margin-right: 15px;
        }

        .back-button-icon {
          svg {
            fill: $grey;
          }

          margin-right: 15px;
        }
      }

      .back-button:hover {
        .back-button-text {
          color: darken($grey, 20%);
        }

        svg {
          fill: darken($grey, 20%);
        }
      }
    }

    .bottom-hint {
      padding: 15px 0;
      font: 18px/28px $PR;

      font-style: normal;
      font-weight: normal;
      text-align: center;
      color: $grey;
    }

    .user_auth_buttons {
      display: flex;
      justify-content: center;
      padding-top: 19px;
      @media (max-width: 576px) {
        padding-top: 0;
      }

      .have_account {
        font: $PR;
        font-weight: 600;
        size: 16px;
        color: $dark;
        line-height: 19px;
        text-align: center;
        margin-right: 3px;
      }

      &__divider {
        border-radius: 8px;
        width: 1px;
        height: 19px;
        background: $grey;
        margin: 0 12px;
      }
      &__link {
        font: 16px/19px $PR;
        font-style: normal;
        font-weight: 600;
        text-align: center;
        color: $violet-light;
        @media (max-width: 576px) {
          font-size: 14px;
        }
      }
    }
  }
}
