.location-search-custom {
    &.location-search {
        .ant-input {
            width: 100%;
            background: #f4f7fc;
            border-radius: 12px;
            padding: 16px 24px;
            font: 16px/20px "Proxima Nova Semibold";
            color: #1B235E;
            outline: none;
            margin: auto auto 16px;
            border: none;
        }
    }

    &.ant-select-dropdown {
        padding: 0;
        border-radius: 12px;
        --tw-shadow: 4px 4px 40px rgba(0,0,0,.15);
        --tw-shadow-colored: 4px 4px 40px var(--tw-shadow-color);
        box-shadow: var(--tw-ring-offset-shadow,0 0 #0000),var(--tw-ring-shadow,0 0 #0000),var(--tw-shadow);

        .ant-select-item {
            padding: 8px 24px;
            font: 16px/20px "Proxima Nova", sans-serif;
            font-weight: 400;
            font-size: 14px;
        }
    }
}