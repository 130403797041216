@import "src/_variables.scss";


.favorites-modal {
  .ant-modal-content {
    border-radius: 24px;
  }

  &__close-btn {
    .ant-btn {
      position: absolute;
      top: 24px;
      right: 24px;
      background: $background;
      box-shadow: 0 6px 32px rgba(0, 0, 0, 0.02);
      border-radius: 12px;
      border: none;
      padding: 17px;
      height: auto;
      line-height: 0;

      svg {
        fill: $grey;
        width: 10px;
        height: 10px;
      }
    }
  }

  &__body {
    display: block;
    color: $dark-blue;
    padding: 30px 0 0;
    margin: auto;
    text-align: center;

    &__image {
      margin: auto;
    }

    &__title {
      margin-top: 35px;
      font: 32px/36px $PB;
      font-weight: 700;
    }
    &__description {
      padding-top: 16px;
      padding-bottom: 20px;
      font: 16px/24px $PR;
      font-style: normal;
      font-weight: 400;
    }
    &__redirect-btn {
      margin: 10px 0;
      .ant-btn {
        background: linear-gradient(90deg, #9571F6 0%, $violet 100%);
        color: $white;
        border-radius: 16px;
        font: 16px $PB;
        padding: 0;
        border: none;
        height: auto;
        transition: all 0.3s;

        svg {
          fill: $white;
          margin-left: 16px;
        }

        &:hover,
        &:focus {
          background-size: 300% 100%;
          background-position: 0 100%;
          background-image: linear-gradient(90deg, #9571F6 0%, $violet 100%);
          color: $white;
        }

        .button__text {
          padding: 20px 44px;
        }
      }
    }
  }
}
