@import "src/_variables.scss";

.history-info-container {
  display: block;
  padding: 12px 64px 150px;
  .history-info-title {
    color: $dark-blue;
    font: 22px/27px $PB;
    font-style: normal;
    font-weight: 700;
    padding-bottom: 20px;
    padding-left: 0 !important;
  }
  .property-history-info {
    padding-bottom: 16px;
    position: relative;
    width: 450px;
    &__chart {
      padding-bottom: 16px;
      position: relative;
      .chart {
        background: rgba(255, 255, 255, 0.3);
        filter: blur(4px);
        width: 442px;
        height: 185px;
      }
      .title {
        position: absolute;
        top: 38%;
        font: 14px/16px $PSB;
        font-style: normal;
        font-weight: 600;
        text-align: center;
        color: $grey;
        text-shadow: 0 4px 28px $white, 0 4px 40px $white;
        width: 100%;
      }
    }
    &__table {
      padding-bottom: 20px;
      .ant-table-thead > tr > th {
        background: $white;
        border: none;
        font: 12px/120% $PR;
        color: $grey;
        font-style: normal;
        font-weight: 400;
        &::before {
          display: none;
        }
      }
      .ant-table-row {
        &:hover {
          background: $white;
        }
      }
      .ant-table-tbody > tr > td {
        border: none;
      }
      .table-cell {
        font: 14px/18px $PSB;
        font-style: normal;
        font-weight: 600;
        color: $dark-blue;
        padding: 6px;
      }
    }
    &__button {
      align-items: center;
      cursor: pointer;
      display: flex;
      &__icon {
        fill: $violet;
        margin-top: 1px;
        transform: rotate(-90deg);
        &.isOpened {
          transform: rotate(90deg);
        }
      }
      &__text {
        color: $violet;
        font: 12px/15px $PSB;
        font-style: normal;
        font-weight: 600;
        letter-spacing: 0.01em;
        padding-right: 7px;
      }
    }
  }

  .tax-history-info {
    &__table {
      .ant-table {
        border-radius: 0;
      }
      .ant-table-thead > tr > th {
        background: $white;
        border: none;
        font: 12px/120% $PR;
        color: $grey;
        font-style: normal;
        font-weight: 400;
        &::before {
          display: none;
        }
      }
      .ant-table-row {
        &:hover {
          background: $white;
        }
      }
      .ant-table-tbody > tr > td {
        border: none;
      }
      .table-cell {
        font: 14px/18px $PSB;
        font-style: normal;
        font-weight: 600;
        color: $dark-blue;
        padding: 6px 50px 6px 0;
        .property-tax {
          display: flex;
          .tax-difference {
            color: $green2;
            &.isNegative {
              color: $red;
            }
          }
        }
      }
    }
  }
}
