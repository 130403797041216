@import "src/_variables.scss";

.calculating-step-container {
  padding: 50px 0;
  @media (max-width: 1440px) {
    padding: 10px 0;
  }

  .calculating-step {
    &__title{
      display: flex;
      text-align: center;
      max-width: 670px;
      margin: auto;
    }

    &__titletext {
      padding: 15px 0;
      font: 40px/44px $PSB;
      font-style: normal;
      font-weight: 600;
      text-align: center;
      color: #1B235E;
      @media (max-width: 1440px) {
        font: 36px/40px $PSB;
      }
      margin: auto;
    }

    &__loader {
      border: 4px solid #f3f3f3;
      border-top: 4px solid #9593F4;
      //border-color: linear-gradient(#ddf2ff 10%,#c7c6ff);
      border-radius: 50%;
      width: 40px;
      height: 40px;
      animation: spin 1s linear infinite;
      margin: auto;
      float: left;
    }

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }

    &__image {
      margin: 20px 0 20px 80px;
      @media (max-width: 1440px) {
        margin: auto 90px auto auto;
        height: 60vh;
        width: 138vh;
      }
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

      display: flex;
      justify-content: center;
      overflow: hidden;
    }
  }
}
