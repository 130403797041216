@import "src/_variables.scss";

.score-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 52px;
  width: 52px;
  border-radius: 29px;
  font-weight: bold;
  font-style: normal;
  font: 24px $PSB;
  min-width: 52px;
  &.no-background {
    background: #F4F7FC;
    color: $grey;
  }
}
