.toggle-wrapper  {

  .ant-click-animating-node {
    display: none;
  }

  .ant-switch-checked {
    .ant-switch-handle {
      left: calc(100% - 40px - 2px);
      &::before {
        background: linear-gradient(90deg, #9571F6 0%, #9571F6 0.01%, #583EF5 100%) !important;
        box-shadow: 0px 4px 16px 5px rgba(88, 62, 245, 0.13) !important;
      }
    }
  }
  .ant-switch {
    .ant-switch-handle {
      width: 40px;
      height: 28px;
      border-radius: 24px;
    }
    .ant-switch-handle::before {
      width: 40px;
      height: 28px;
      border-radius: 24px;
      background: #F4F7FC;
      box-shadow: 0px 4px 16px 5px rgba(88, 62, 245, 0.08);
    }
    width: 72px;
    height: 32px;
    background: #F4F7FC;
    box-shadow: inset 0px 2px 4px rgba(149, 113, 246, 0.15);
  }
}