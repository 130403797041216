@import "src/_variables.scss";

.neighborhood-table-list {
  background-color: #FFFFFF;
  border-radius: 24px;
  width: 204px;
  padding: 10px;
  position: absolute;
  top: 16px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  gap: 5px;
  right: 60px;

  .checkbox-wrapper {
    width: 129px;
    height: 24px;
    margin-top: 6%;
    display: flex;
    align-items: center;
    gap: 5px;

    .checkbox__text{
      white-space: nowrap;
    }

  .ant-checkbox-wrapper {
    display: flex;
    align-items: center;
    color: $dark-blue;
    font: 14px/17px $PSB;
    margin-bottom: 16px;
  }

  .ant-checkbox {
    .ant-checkbox-inner {
      width: 24px;
      height: 24px;
      background: $background;
      box-shadow: inset 0px 2px 4px rgba(149, 113, 246, 0.15);
      border-radius: 8px;
      border: none;

      input[type='checkbox'] {
        display: none;
      }
    }
  }

  .ant-checkbox-checked .ant-checkbox-inner::after {
    display: none;
  }

  .ant-checkbox-checked .ant-checkbox-inner::before {
    position: absolute;
    content: "";
    width: 15px;
    height: 12px;
    background: url("/images/icon-checkbox.svg") no-repeat center/100%;
    left: 0;
    right: 0;
    margin: auto;
    top: 50%;
    transform: translateY(-50%);
  }

  .ant-checkbox-checked::after {
    display: none;
  }
  }
}
