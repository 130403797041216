@import "src/_variables.scss";

.select-range-field {
  &__selects {
    display: flex;
    align-items: center;
    width: 100%;
    padding-top: 6px;

    .custom-select-addon {
      margin-bottom: 10px;
      font: 12px/15px $PR;
      color: #A9A9C4;
    }

    .ant-select {
      min-width: 160px;
      border: none;
      background: $background;
      border-radius: 12px !important;
      font: 16px/19px $PB;
      color: $dark-blue;
      padding: 9px;
      .ant-select-arrow {
        right: 15%;
      }
    }

    .dash {
      font: 16px/19px $PB;
      color: $dark-blue;
      margin: 15px;
    }
    .input-star {
      position: relative;

      &:after {
        position: absolute;
        content: "";
        width: 16px;
        height: 16px;
        right: 8px;
        top: 50%;
        background: url("/images/icon-star.svg") no-repeat center/100%;
      }
    }
    .ant-select:focus,
    .ant-select-focused {
      box-shadow: none;
    }
  }

  &__inputs {
    display: flex;
    align-items: center;
    margin-top: 28px;

    .dash {
      font: 16px/19px $PB;
      color: $dark-blue;
      margin: 15px;
    }

    .input-star {
      position: relative;

      &:after {
        position: absolute;
        content: "";
        width: 16px;
        height: 16px;
        right: 8px;
        top: 50%;
        transform: translateY(-50%);
        background: url("/images/icon-star.svg") no-repeat center/100%;
      }
    }

    .ant-input-wrapper {
      position: relative;

      .ant-input-group-addon {
        position: absolute;
        bottom: calc(100% + 9px);
        left: 0;
        font: 12px/15px $PR;
        color: $grey2;
        background-color: transparent;
        border: none;
        padding: 0;
      }

      .ant-input {
        border: none;
        background: $background;
        border-radius: 12px !important;
        font: 16px/19px $PB;
        color: $dark-blue;
        padding: 17px;
      }

      .ant-input:focus,
      .ant-input-focused {
        box-shadow: none;
      }
    }

    .input-container {
      position: relative;

      &__title {
        display: flex;
        position: absolute;
        width: 102%;
        bottom: calc(100% + 9px);
        left: 0;
        font: 12px/15px $PR;
        color: $grey2;

        .input-number-icon {
          margin-left: auto;
        }
      }

      &__field {
        position: relative;

        .ant-input {
          border: none;
          background: $background;
          border-radius: 12px !important;
          font: 16px/19px $PB;
          color: $dark-blue;
          padding: 17px;
        }

        .ant-input:focus,
        .ant-input-focused {
          box-shadow: none;
        }

        &.input-star {
          &:after {
            position: absolute;
            content: "";
            width: 16px;
            height: 16px;
            right: 8px;
            top: 50%;
            transform: translateY(-50%);
            background: url("/images/icon-star.svg") no-repeat center/100%;
          }
        }
      }
    }
  }
}

.location-search {
  &__tags {
    width: 100%;
    text-align: center;
  }

  &__input {
    max-width: calc(100% - 64px);
    margin: 15px auto auto;
  }

  .location-tag {
    position: relative;
    font: 10px/16px $PSB;
    background: $background;
    padding: 6px 24px 6px 16px;
    border-radius: 12px;
    color: $dark-blue;
    margin-bottom: 8px;
    border: none;
    max-width: 144px;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;

    .ant-tag-close-icon {
      position: absolute;
      right: 14px;
      top: 50%;
      transform: translateY(-50%);
      color: $grey2;
    }
  }

  &__label {
    display: block;
    color: $grey2;
    font: 12px/15px $PR;
    margin-bottom: 4px;
  }

  &__sub-title {
    max-width: calc(100% - 64px);
    white-space: break-spaces;
    margin: 15px auto auto;
    display: block;
    color: $grey2;
    font: 12px/15px $PR;
    margin-bottom: 4px;
  }

  .location-search-input {
    width: 100%;
    background: $background;
    border-radius: 12px;
    padding: 16px 24px;
    font: 16px/20px $PSB;
    color: $dark-blue;
    outline: none;
    margin: auto auto 16px;
  }
}

.ant-tooltip-content {
  .ant-tooltip-arrow {
    display: none;
  }

  .ant-tooltip-inner {
    font: 12px/15px $PSB;
    box-shadow: 0px 6px 32px rgba(0, 0, 0, 0.02);
    border-radius: 4px;
    background: $dark-blue;
    min-height: auto;
  }

}

.filters-title {
  display: flex;
  padding-bottom: 10px;
  align-items: center;
  font: 16px/19px $PSB;
  color: $dark-blue;
}

.filters-icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(180deg, $background 0%, $white 100%);
  box-shadow: inset 0px 4px 4px rgba(147, 157, 244, 0.17);
  border-radius: 16px;
  width: 32px;
  height: 32px;
  margin-right: 12px;

  svg {
    width: 16px;
    height: 16px;
  }
}

.image-bar {
  width: 100%;
}

.priority-field {
  &__slider {
    width: 85%;

    &.ant-slider {
      margin: 55px auto 0;
    }

    .ant-slider-handle {
      background: linear-gradient(90deg, #9571F6 0%, $violet 100%);
      box-shadow: 0px 4px 16px 5px rgba(88, 62, 245, 0.13);
      border-radius: 50%;
      width: 20px;
      height: 20px;
      border: 2px solid $white;
      margin-top: -7px;
      margin-left: 5px;

      &:focus {
        border: 2px solid $white;
        box-shadow: 0px 6px 32px rgba(0, 0, 0, 0.02);
      }
    }

    &.ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
      box-shadow: 0px 6px 32px rgba(0, 0, 0, 0.02);
      border: 2px solid $white;
      background: linear-gradient(90deg, #9571F6 0%, $violet 100%);
    }

    .ant-slider-rail {
      background: $background;
      box-shadow: inset 0px 2px 4px rgba(149, 113, 246, 0.15);
      border-radius: 8px;
      height: 8px;
    }

    .ant-slider-dot {
      background: $grey3;
      box-shadow: inset 0px 2px 4px rgba(149, 113, 246, 0.15);
      border-radius: 8px;
      width: 16px;
      height: 16px;
      border: none;
      top: -4px;
    }

    .ant-slider-track {
      background: $violet;
      border-radius: 8px;
      height: 8px;
    }

    &.ant-slider:hover .ant-slider-track {
      background: $violet;
    }

    .ant-slider-mark {
      top: -40px;
    }

    .ant-slider-mark-text {
      max-width: 56px;
      width: 100%;
      color: $grey2;
      font: 12px/14px $PR;
      max-width: 56px;
      text-align: center;
    }
  }

  .ant-slider-dot-active {
    background: $violet;
  }
}

.simple-input.ant-input-affix-wrapper, .simple-input.ant-input  {
  background: $background;
  border-radius: 12px;
  font: 16px/19px $PSB;
  color: $dark-blue;
  padding: 16px 24px;
  border: none;

  &:focus,
  &.ant-input-affix-wrapper-focused {
    box-shadow: none;
  }

  input.ant-input {
    font: 16px/19px $PSB;
    color: $dark-blue;
    background: $background;

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px $background inset !important;
    }

    &:focus,
    &:hover {
      background: $background;
    }
  }
}
  .ant-form-item-has-error {

  & :not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
  & :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
  & :not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover,
  & :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
    background: $background;
    border: none;
    box-shadow: none;
  }
}

.MuiSlider-track {
  background: linear-gradient(90deg, #9571F6 30%, #583EF5 100%) !important;
  border: none !important;
}

.distance-fields {
  display: grid;
  grid-template-columns: 50% 50%;

  .address-title {
    color: #A9A9C4;
    font: 12px/15px $PR;
    padding-top: 10px;
    padding-bottom: 4px;
  }

  .location-search__input {
    max-width: calc(100% - 33px);
    margin: 0;
  }

  .switcher {
    display: flex;
    align-items: center;
    width: fit-content;
    height: 40px;
    box-shadow: none;
    background: #F4F7FC;

    .switch-button {
      width: auto;
      height: 80%;
      background: #F4F7FC;
      border-radius: 24px;
      border-color: #F4F7FC;
    }
  }
  .ant-form-item {
    margin-bottom: 10px;
  }
}

.movement-type {
  padding-top: 35px; // 10px
}
