@import "src/_variables.scss";

.locations-search-form {
  position: relative;
  .ant-select {
    box-shadow: 0 6px 10px rgba(161, 181, 216, 0.22), inset 0 4px 4px rgba(147, 157, 244, 0.17);
    border-radius: 16px;
    width: 100%;

    .ant-select-multiple, .ant-select-selector {
      height: 56px;
      background: linear-gradient(180deg, #F4F7FC 0%, #FFFFFF 100%);
      box-shadow: inset 0 4px 4px rgba(147, 157, 244, 0.17);
      border-radius: 16px !important;
      border: none !important;
      padding-right: 50px;

      .ant-select-selection-search {
        margin-left: 4px;
      }

      input {
        width: 100%;
        min-width: 350px;
        font: 16px/20px $PSB;
        color: #1B235E;
        padding: 16px;
      }

      .ant-select-selection-placeholder {
        color: #A9A9C4;
        font: 16px/20px $PR;
        padding: 16px 8px;
      }

      .ant-select-selection-item {
        background: #F4F7FC;
        border-radius: 12px;
        font: 10px/16px $PSB;
        font-style: normal;
        font-weight: 600;
        color: $dark-blue;
        flex: none;
        order: 0;
        flex-grow: 0;
        align-items: center;
      }

      .ant-select-selection-overflow {
        margin-left: 10px;
      }
    }
  }
  .search-button {
    position: absolute;
    top: 0;
    right: 26px;
    .ant-btn {
      height: 56px;
      border: none;
      background: transparent;
      padding: 0;
      .button__text {
        padding-top: 6px;
      }
      &:disabled {
        svg {
          fill: $grey2;
        }
      }
      &:hover {
        background: none;
      }
    }
  }
  &__dropdown {
    padding: 0;
    .ant-select-item-option {
      font: 14px/18px $PR;
      padding: 8px 24px !important;
    }
    .ant-select-item-option-selected {
      font: 14px/18px $PSB;
      padding: 8px 24px !important;
    }
    &.ant-select-dropdown {
      border-radius: 12px;
    }
  }
}
