@import "src/_variables.scss";

.not-found-page {
  display: block;
  border-radius: 24px;
  margin: auto;

 .button-redirect-dashboard {
   justify-content: center;
   position: absolute;
   bottom: 15vh;
   text-align: center;
   width: 100%;

         .ant-btn {
           background: linear-gradient(90deg, #9571F6 0%, $violet 100%);
           color: $white;
           border-radius: 16px;
           font: 18px $PB;
           padding: 0;
           border: none;
           height: auto;
           transition: all 0.3s;


           &:hover,
           &:focus {
             background-size: 300% 100%;
             background-position: 0 100%;
             background-image: linear-gradient(90deg, #9571F6 0%, $violet 100%);
             color: $white;
           }

           &:disabled {
             background: $grey-light2;
             color: $grey2;
             cursor: default;

             svg {
               fill: $grey2;
             }
           }

           .button__text {
             padding: 20px 150px;
           }

         }
 }


 &__messages{
   white-space: nowrap;
   font: 36px/40px $PB;
   color: #1B235E;
   position: absolute;
   bottom: 35vh;
   text-align: center;
   width: 100%;
 }

  &__image {
    display: flex;
    justify-content: center;
    padding: 20px;
    //height: 100vh;
 }

}