@import "src/_variables.scss";

.place-description {
  display: block;
  padding-right: 10px;
  width: 150px;
  text-overflow: ellipsis;
  &.isMap {
    width: unset;
  }
  .place-name {
    font: 16px/19px $PSB;
    font-style: normal;
    font-weight: 600;
    color: $dark-blue;
    text-transform: capitalize;
    padding-bottom: 2px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    &.isMap {
      font: 14px/17.05px $PSB;
      text-overflow: unset;
      overflow: unset;
      white-space: unset;
    }
  }
  .place-common {
    display: flex;
    &__kind {
      font: 14px/120% $PSB;
      font-style: normal;
      font-weight: 600;
      flex: none;
      order: 0;
      flex-grow: 0;
      padding-right: 6px;
      text-transform: capitalize;
      max-width: 100px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      .transit-kinds {
        display: flex;
      }
      &.isMap {
        color: #29BADF;
        font: 12px/14.4px $PSB;
      }
    }
    &__distance {
      font: 14px/120% $PR;
      font-style: normal;
      font-weight: 400;
      color: $grey2;
      flex: none;
      order: 0;
      flex-grow: 0;
      &.isMap {
        font: 12px/14.4px $PR;
      }
    }
  }
}
