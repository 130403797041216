.name-form {
  display: flex;
  justify-content: center;

  .name-field {
    padding: 10px 20px;
  }

  @media (max-width: 768px) {
    display: block;

    .name-field {
      padding: 0;
    }
  }

  @media (max-width: 768px) {
    .name-field {
      .simple-input.ant-input {
        font-size: 14px;
        color: #1B235E;
        padding: 12px 24px;
      }
    }
  }
}
