@import "src/_variables.scss";

.results-filters-bar-container {
  background: $white;
  padding: 24px 24px 0;
  display: flex;
  min-height: 100px;
  box-shadow: 0 6px 32px rgba(0, 0, 0, 0.02);
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  flex-wrap: wrap;
  .criteria-buttons {
    display: flex;
    flex-wrap: nowrap;
    max-width: 600px;
    overflow-x: auto;
    &__button {
      align-items: center;
      justify-content: center;
      display: flex;
      min-width: 56px;
      height: 56px;
      margin-right: 8px;
      max-width: 14ch;
      color: $dark-blue;
      font: 14px/17px $PSB;
      cursor: pointer;
      margin-bottom: 15px;

      background: linear-gradient(180deg, $background 0%, $white 100%);
      box-shadow: 0 6px 10px rgba(161, 181, 216, 0.22), inset 0px 4px 4px rgba(147, 157, 244, 0.17);
      border-radius: 16px;
      border: none;

      &:hover,
      &:focus {
        background: linear-gradient(180deg, $background 0%, $white 100%);
      }

      &.isActive {
        background: linear-gradient(180deg, #DDF2FF 0%, #DEDDFF 100%);
        box-shadow: 0 6px 10px rgba(161, 181, 216, 0.22), inset 0 4px 4px rgba(147, 157, 244, 0.17);
      }
      &.isFilled {
        min-width: 72px;
      }
      &__icon {
        display: flex;
        margin: auto;
        width: 56px;
        height: 100%;
        align-items: center;
        justify-content: center;
      }
      &__text {
        height: 17px;
        align-items: center;
        padding: 0 8px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
    &__more-button {
      color: $dark-blue;
      font: 14px/17px $PSB;
      width: 106px;
      height: 56px;
      margin-left: 30px;
      margin-bottom: 15px;

      background: linear-gradient(180deg, $background 0%, $white 100%);
      box-shadow: 0 6px 10px rgba(161, 181, 216, 0.22), inset 0 4px 4px rgba(147, 157, 244, 0.17);
      border-radius: 16px;
      border: none;

      &[disabled] {
        background: linear-gradient(180deg, $background 0%, $white 100%);
        box-shadow: inset 0 4px 4px rgba(147, 157, 244, 1);
        opacity: 0.17;
        cursor: default;
        pointer-events: none;

        .icon-button-content {
          &__icon {
            box-shadow: inset 0 4px 4px rgba(147, 157, 244, 1);
          }
        }
      }
      &:hover,
      &:focus {
        background: linear-gradient(180deg, $background 0%, $white 100%);
        color: $dark-blue;
      }

      &.isActive {
        background: linear-gradient(180deg, #DDF2FF 0%, #DEDDFF 100%);
        box-shadow: 0 6px 10px rgba(161, 181, 216, 0.22), inset 0 4px 4px rgba(147, 157, 244, 0.17);
      }

      .button__text {
        display: flex;
        justify-content: center;
      }

      svg {
        width: 16px;
        height: 16px;
        margin-right: 12px;
      }
    }
  }
}
