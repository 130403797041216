@import './src/variables';


.ant-tooltip-inner {
    border-radius: 4px;
    display: initial;
    background: #1B235E;
    color: white;
    white-space: pre;
    height: 23px;
    padding: 1px 5px 1px 5px;
    font: 12px $PSB;
    align-items: center;
}