@import "src/_variables.scss";

@font-face {
	font-family: 'Proxima Nova Regular';
	src: url('fonts/ProximaNova-Regular.eot');
	src: url('fonts/ProximaNova-Regular.eot?#iefix') format('embedded-opentype'),
		url('fonts/ProximaNova-Regular.woff') format('woff'),
		url('fonts/ProximaNova-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Proxima Nova Semibold';
	src: url('fonts/ProximaNova-Semibold.eot');
	src: url('fonts/ProximaNova-Semibold.eot?#iefix') format('embedded-opentype'),
		url('fonts/ProximaNova-Semibold.woff') format('woff'),
		url('fonts/ProximaNova-Semibold.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Proxima Nova Bold';
	src: url('fonts/ProximaNova-Bold.eot');
	src: url('fonts/ProximaNova-Bold.eot?#iefix') format('embedded-opentype'),
		url('fonts/ProximaNova-Bold.woff') format('woff'),
		url('fonts/ProximaNova-Bold.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@import './_variables.scss';

* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  min-width: 320px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-family: $PR, sans-serif;
  overflow-x: hidden;
  scrollbar-width: thin;
  scrollbar-color: #D0D1E1 #f5f5f5;

  &::-webkit-scrollbar {
      height: 6px;
      height: 6px;
  }
  &::-webkit-scrollbar-track {
      background: #f5f5f5;
  }
    
  &::-webkit-scrollbar-thumb {
      background-color: #D0D1E1;
      border-radius: 4px;
      border: 0;
  }
}

body {
  min-width: 320px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-family: $PR, sans-serif;
  overflow-x: hidden;
 
  .ant-image-preview-switch-left,
  .ant-image-preview-switch-right {
	color: #ffffff;
	background: #000000;
  }
}

a {
  text-decoration: none;
}

ul li {
  display: inline-block;
  list-style: none;
}

input,
textarea,
button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0;
  border: none;
}

textarea {
  resize: none;
  overflow: auto;
}

.fs-24 {
	font-size: 24px;
}

.fs-16 {
	font-size: 16px;
}

.bold {
	font-weight: bold;
}

.green-value {
color: $green;
background: #E2F3EF;
}

.green-yellow-value {
color: $green-yellow;
background: #EEF3E2;
}

.grey-value {
color: #908DB9;
background: #F4F7FC;
}

.yellow-value {
color: $yellow-dark;
background: #fffcdc;
}

.orange-value {
color: $orange-dark;
background: #fff1dc;
}

.red-value {
color: $red-dark;
background: #fee5e5;
}

.green-background {
color: $white;
background: #4AB272;
}

.light-green-background {
color: $white;
background: #E2F3EF;
}

.green-yellow-background {
color: $white;
background: #BADF51;
}

.light-green-yellow-background {
color: $white;
background: #EEF3E2;
}

.yellow-background {
color: $white;
background: #F5E237;
}

.light-yellow-background {
color: $white;
background: #FFFBD3;
}

.orange-background {
color: $white;
background: #FFA425;
}

.light-orange-background {
color: $white;
background: #FFF1DC;
}

.red-background {
color: $white;
background: $red;
}

.light-red-background {
color: $white;
background: #FEE5E5;
}

.status-green {
  color: #4AB272;
}

.status-orange {
  color: #FFA425;
}

.status-grey {
  color: #908DB9;
}

.blue-light-value {
color: $blue-light;
}

.orange-light-value {
  color: $orange-light;
}

.salmon-value {
  color: $salmon;
}

.purple-light-value {
  color: $purple-light;
}

.green-light-value {
  color: $green-light;
}

.grey2-value {
  color: $grey2;
}

.hidden-tooltip {
  display: none !important;
}