@import "src/_variables.scss";

.favorites-button-container {
  .favorites-button {
    border-radius: 12px;
    background: $white;
    border: 1px solid $grey-light;

    &.small {
      width: 40px;
      height: 40px;
    }
    &.medium {
      width: 50px;
      height: 50px;
    }
    &.large {
      width: 60px;
      height: 60px;
    }
    .ant-btn:hover,
    .ant-btn:focus {
      border: 1px solid $grey-light;
    }

    svg {
      //position: absolute;
      //left: 30.12%;
      //top: 32.44%;
      vertical-align: middle;
      fill: $violet-light;
    }
  }

  .ant-btn {
    padding: 5px;
  }

  .active {
    background: linear-gradient(90deg, #9571F6 0%, $violet 100%);
    &:focus,
    &:hover {
      background: linear-gradient(90deg, #9571F6 0%, #583EF5 100%);
    }

    svg {
      fill: $white;
    }
  }
}
