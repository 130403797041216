@import "src/_variables.scss";

.other-info-container {
  padding: 32px 64px;
  .other-info-title {
    color: $dark-blue;
    font: 22px $PSB;
    font-style: normal;
  }
  .ant-row {
    padding-top: 20px;
  }
  .other-info-section {
    &__title {
      color: $dark-blue;
      font: 16px $PB;
      padding-bottom: 16px;
    }
    .criteria-item {
      display: block;
      padding-bottom: 12px;
      &__title {
        color: $grey3;
        font: 12px $PSB;
        padding-bottom: 4px;
      }
      &__value {
        color: $dark-blue;
        font: 14px/18px $PSB;
        font-weight: 600;
      }
    }
  }
}
