@import "src/_variables.scss";

.onboarding-container {
  display: flex;
  justify-content: center;

  &.withProgressBar {
    display: block;
  }

  .onboarding-progress-bar {
    width: 100%;
    margin: 0 auto;
    padding: 20px 0;
    max-width: 392px;

    .ant-steps-horizontal:not(.ant-steps-label-vertical) {
      .ant-steps-item-tail {
        display: block;
        top: 50% !important;
        transform: translateY(-50%);
        height: 2px;
        background: #583EF5;
        right: 0;
        left: 64px;
        width: auto;
      }

      .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
        background: #583EF5;
      }
      .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-tail::after {
        background: #583EF5;
      }

      .ant-steps-item {
        padding-left: 12px;

        &:first-child {
          padding-bottom: 5px;
        }

        &:last-child {
          .ant-steps-item-tail {
            display: none;
          }
        }
      }
    }
  }
}