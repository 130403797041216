@import "src/_variables.scss";

.form-input {
  .simple-input {
    background: #F4F7FC !important;
  }
  .ant-input-affix-wrapper-disabled {
    background: #EBECEF !important;
  }
  .ant-input-disabled {
    background: #EBECEF !important;
  }
  .input-with-button {
    display: flex;
    align-items: baseline;

    .ant-form-item {
      width: 100%;
    }

    &__button {
      display: flex;
      margin-left: auto;

      svg {
        fill: $white;
        margin-left: 16px;
        width: 16px;
        height: auto;
      }
    }
  }
  &__message {
    font:12px $PR;
    color: #A9A9C4;
    padding-bottom: 5px;
  }
}