@import "src/_variables.scss";

.progress-bar-container {
  .ant-steps-item-icon {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    .ant-progress-inner {
      width: 50px !important;
      height: 50px !important;
    }
  }
  .ant-steps-with-progress {
    .ant-steps-item-icon .ant-progress {
      top: 50%;
      right: auto;
      bottom: auto;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .ant-steps-item {
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }
  .ant-steps-item-finish .ant-steps-item-icon {
    background-color: $violet;
    border-color: $violet;
    > .ant-steps-icon {
      color: $white;
    }
  }
  .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
    background-color: $violet;
    height: 1.5px;
  }
  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
    background-color: $grey3;
    height: 1.5px;
  }
  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
    background-color: $violet;
    border-color: $violet;
    .ant-steps-icon {
      color: $white;
      //font: 18px/20px $PR;
      font: 16px/18px $PR;
      font-style: normal;
      font-weight: 600;
    }
  }
  .ant-progress-circle-trail {
    stroke: $grey3;
  }
  .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
    stroke: $violet;
  }
  .ant-progress-status-success .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
    stroke: $violet;
  }
  .ant-steps-item-wait .ant-steps-item-icon {
    border-color: $grey3;
    border-width: 1.5px;
    > .ant-steps-icon {
      color: $grey;
      //font: 18px/20px $PR;
      font: 16px/18px $PR;
      font-style: normal;
      font-weight: 600;
    }
  }
}
