@import "src/_variables.scss";

.results-header-wrapper {
  display: block;
  .results-header {
    display: flex;
    justify-content: space-between;
    height: 60px;
    margin: 40px 0 10px;

    .results-header-title {
      display: flex;
      align-self: end;
      &__text {
        font: 22px/27px $PB;
        color: $dark-blue;
      }
      &__results {
        padding-left: 24px;
        padding-top: 5px;
        font: 16px $PSB;
        color: #908DB9;
      }
    }
    .results-header-buttons {
      display: flex;
      &__left {
        padding-right: 20px;
      }
    }
  }
}
