@import "src/_variables.scss";

.place-icon-container {
  display: block;
  position: relative;
  width: 32px;
  height: 41px;
  .vector-icon {
    width: 100%;
    height: 100%;
  }
  .circle-icon {
    display: flex;
    height: 24px;
    width: 24px;
    min-width: 24px;
    position: absolute;
    top: 4px;
    left: 4px;
  }
  .place-icon {
    width: 16px;
    height: 16px;
  }
}
