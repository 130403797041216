@import "src/variables";

.list-view-wrapper {
  max-width: 100%;
  width: 100%;
  .houses-results-container {
    min-height: 400px;
    .ant-table-cell.is-active {
      .anticon {
        transform: rotate(180deg);
      }
    }
    .ant-spin-nested-loading {
      height: 100%;
    }

    .recently-added-button {
      align-items: center;
      position: absolute;
      left: 262px;
      top: 13.5px;
      z-index: 10;
      white-space: nowrap;
      display: flex;
      cursor: pointer;
      color: $grey;
      font-weight: 700;
      font: 14px/16px $PSB;

      &__icon {
        height: 24px;
        align-items: center;
        margin-left: 12px;
        transition: 0.3s;
        display: inline-flex;
        &.is-active {
          transform: rotate(180deg);
        }
        svg {
          width: 11px;
          height: 11px;
        }
      }
    }

    .hiscore-column {
      display: flex;
      align-items: center;
      width: 405px; // 364px
      min-width: 364px;
      &__col {
        padding: 10px 24px;
      }
      &__score {
        box-shadow: 0 6px 10px rgba(161, 181, 216, 0.22), inset 0px 4px 4px rgba(147, 157, 244, 0.17);
        border-radius: 50%;
        margin-right: 20px;
        .score-container {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 52px;
          width: 52px;
          border-radius: 29px;
          font-weight: bold;
          font-style: normal;
          font: 24px $PSB;
        }
      }

      &__house {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-grow: 1;
        min-width: 0;
        .house-info {
          padding-left: 15px;
          padding-right: 15px;

          &__address {
            display: grid;
            font: 14px/17px $PB;
            color: $dark-blue;
            transition: 0.3s;

            &.isViewed {
              color: $grey2;
            }
          }

          &__general {
            display: block;
            font: 10px/16px $PSB;
            margin-top: 10px;
            &__first-row {}
            &__second-row {
              align-items: center;
              color: $grey2;
              display: flex;
            }
          }
        }
      }

      &:hover {
        .house-info__address {
          color: #9571F6;
        }
      }
    }

    .list-view__table {
      .ant-table {
        border-radius: 0 0 24px 24px;
      }
      &.isFavorites {
        .ant-table {
          border-radius: 24px;
        }
      }
      .ant-table-content {        
        scrollbar-width: thin;
        scrollbar-color: #D0D1E1 #f5f5f5;

        &::-webkit-scrollbar {
            width: 6px;
            height: 6px;
        }
        &::-webkit-scrollbar-track {
            background: #f5f5f5;
        }
          
        &::-webkit-scrollbar-thumb {
            background-color: #D0D1E1;
            border-radius: 4px;
            border: 0;
        }
      }
      .ant-table-thead {
        position: sticky;
        top: 0;
        z-index: 5;
      }
      .ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
        display: none;
      }

      .ant-table-tbody>tr>td {
        border: none;
      }

      .ant-table-tbody>tr {
        cursor: pointer;

        &:nth-of-type(even) td {
          background: #FAFAFF;
        }
      }

      .ant-table-thead>tr>th,
      .ant-table-tbody>tr>td,
      .ant-table tfoot>tr>th,
      .ant-table tfoot>tr>td {
        padding: 10px 16px;
      }

      .ant-table-thead>tr>th {
        font: 14px/17px $PSB;
        color: $grey;
        border-bottom: none;
        background: $white;
        white-space: nowrap;
      }

      .ant-table-column-sorter {
        color: $grey;
        margin-left: 12px;
      }

      .ant-table-column-sorters {
        justify-content: flex-start;
      }

      .ant-table-column-sorters:hover .ant-table-column-sorter,
      .ant-table-column-sorters:focus .ant-table-column-sorter {
        color: $grey;
      }

      .ant-table-column-sorter-up.active,
      .ant-table-column-sorter-down.active {
        color: darken($grey, 10%)
      }

      .ant-table-column-sorter-up {
        display: none;
      }

      .ant-table-column-sorter-up.active {
        display: block;
        margin-top: 11px;
      }

      .ant-table-column-sorter-up+.ant-table-column-sorter-down {
        margin-top: 11px;
      }

      .ant-table-column-sorter-up.active+.ant-table-column-sorter-down {
        display: none;
      }


      .ant-table-tbody>tr {
        height: 112px;
        min-height: 112px;
      }


      .ant-table-container table>thead>tr:first-child th:first-child {
        position: sticky;
        z-index: 5;
        left: 0;
      }


      .ant-table-tbody>tr td:first-child {
        position: sticky;
        z-index: 5;
        left: 0;
      }

      .ant-table-thead>tr>th:first-child {
        background: #FFFFFF;
        box-shadow: 8px 0px 32px rgba(0, 0, 0, 0.03);
        z-index: 3;
        pointer-events: none;
        .ant-table-column-sorters {
          pointer-events: none;
          .ant-table-column-title {
              pointer-events: visible;
          }
          .ant-table-column-sorter {
            pointer-events: visible;
            margin-left: 0;
            padding-left: 12px;
          }
        }
      }

      .ant-table-thead>tr>th {
        background: #FFFFFF;
      }

      .ant-table-tbody>tr:nth-of-type(even) td:first-child {
        z-index: 1;
        background: #FAFAFF;
        box-shadow: 8px 0px 32px rgba(0, 0, 0, 0.03);
      }

      .ant-table-tbody>tr:nth-of-type(odd) td:first-child {
        background: #FFFFFF;
        z-index: 1;
        box-shadow: 8px 0px 32px rgba(0, 0, 0, 0.03);
      }

      .ant-table-tbody>tr:nth-of-type(even) td {
        background: #FAFAFF;
      }

      .ant-table-tbody>tr:nth-of-type(odd) td {
        background: #FFFFFF;
      }
    }

  }
}
